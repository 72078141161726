import React, { Fragment } from "react";
import commons from "./common/commons";

const Gallery = () => {
  return (
    <Fragment>
      <div
        className="page-template-default page page-id-13775 theme-arki gdlr-core-body woocommerce-no-js arki-body arki-body-front arki-full arki-with-sticky-navigation arki-blockquote-style-3 gdlr-core-link-to-lightbox"
        data-home-url="/"
      >
        <commons.MobileHeader />
        <div className="arki-body-outer-wrapper mm-page mm-slideout">
          <div className="arki-body-wrapper clearfix arki-with-transparent-header arki-with-frame">
            <commons.Header name="catalogue" />
            <commons.PageHeader
              name="Our Catalogue"
              tag="Tiles Of Different Catalogues"
            />
            <div className="arki-page-wrapper" id="arki-page-wrapper">
              <div className="gdlr-core-page-builder-body">
                <div
                  className="gdlr-core-pbf-wrapper"
                  style={{ padding: "120px 20px 0px 20px" }}
                >
                  <div
                    className="gdlr-core-pbf-background-wrap"
                    style={{ backgroundColor: "#ffffff" }}
                  ></div>
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                      <div className="gdlr-core-pbf-element">
                        <div
                          className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                          style={{ paddingBottom: "60px" }}
                        >
                          <div className="gdlr-core-title-item-title-wrap">
                            <h3
                              className="gdlr-core-title-item-title gdlr-core-skin-title"
                              style={{ textTransform: "none" }}
                            >
                              Catalogue
                              <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="gdlr-core-pbf-element">
                        <div className="gdlr-core-gallery-item gdlr-core-item-pdb clearfix gdlr-core-gallery-item-style-stack-image gdlr-core-item-pdlr">
                          <div className="gdlr-core-gallery-item-holder gdlr-core-js-2">
                            <div className="gdlr-core-item-list gdlr-core-item-mgb">
                              <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                <a
                                  className="gdlr-core-lightgallery gdlr-core-js"
                                  href="upload/catalogue-main-image.jpg"
                                  data-lightbox-group="gdlr-core-img-group-6"
                                >
                                  <img
                                    src="upload/cats/catalogue-main-image.jpg"
                                    alt=""
                                    width="2000"
                                    height="1335"
                                    title="Image Title"
                                  />
                                  <span className="gdlr-core-image-overlay">
                                    <i className="gdlr-core-image-overlay-icon gdlr-core-size-22 icon_zoom-in_alt"></i>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="gdlr-core-pbf-wrapper"
                  style={{ padding: "100px 20px 30px 20px" }}
                >
                  <div className="gdlr-core-pbf-background-wrap"></div>
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full">
                      <div className="gdlr-core-pbf-element">
                        <div
                          className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                          style={{ paddingBottom: "60px" }}
                        ></div>
                      </div>
                      <div className="gdlr-core-pbf-element">
                        <div className="gdlr-core-gallery-item gdlr-core-item-pdb clearfix gdlr-core-gallery-item-style-grid">
                          <div
                            className="gdlr-core-gallery-item-holder gdlr-core-js-2 clearfix"
                            data-layout="fitrows"
                          >
                            <div className="gdlr-core-item-list gdlr-core-gallery-column gdlr-core-column-15 gdlr-core-column-first gdlr-core-item-pdlr gdlr-core-item-mgb">
                              <a
                                href="upload/cats/Glossy-Series.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                  <a
                                    href="upload/cats/Glossy-Series.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src="upload/cats/glossy-tiles-catalogue.png"
                                      alt=""
                                      width="600"
                                      height="800"
                                      title="Image Title"
                                    />
                                    <span className="gdlr-core-image-overlay gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                      <span className="gdlr-core-image-overlay-content">
                                        <span
                                          className="gdlr-core-portfolio-title gdlr-core-title-font"
                                          style={{
                                            fontSize: "23px",
                                            fontWeight: 600,
                                            letterSpacing: "0px",
                                            textTransform: "none",
                                          }}
                                        >
                                          <a
                                            href="upload/cats/Glossy-Series.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Glossy Tiles
                                          </a>
                                        </span>
                                        <span
                                          className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font"
                                          style={{
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                          }}
                                        >
                                          {" "}
                                        </span>
                                      </span>
                                    </span>
                                  </a>
                                </div>
                              </a>
                            </div>
                            <div className="gdlr-core-item-list gdlr-core-gallery-column gdlr-core-column-15 gdlr-core-item-pdlr gdlr-core-item-mgb">
                              <a
                                href="upload/cats/Kitchen-Series.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                  <a
                                    href="upload/cats/Kitchen-Series.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src="upload/cats/kitchen-series-catalogue.png"
                                      alt=""
                                      width="600"
                                      height="800"
                                      title="Image Title"
                                    />
                                    <span className="gdlr-core-image-overlay gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                      <span className="gdlr-core-image-overlay-content">
                                        <span
                                          className="gdlr-core-portfolio-title gdlr-core-title-font"
                                          style={{
                                            fontSize: "23px",
                                            fontWeight: 600,
                                            letterSpacing: "0px",
                                            textTransform: "none",
                                          }}
                                        >
                                          <a
                                            href="upload/cats/Kitchen-Series.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Kitchen Series
                                          </a>
                                        </span>
                                        <span
                                          className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font"
                                          style={{
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                          }}
                                        >
                                          {" "}
                                        </span>
                                      </span>
                                    </span>
                                  </a>
                                </div>
                              </a>
                            </div>
                            <div className="gdlr-core-item-list gdlr-core-gallery-column gdlr-core-column-15 gdlr-core-item-pdlr gdlr-core-item-mgb">
                              <a
                                href="upload/cats/Pooja-Room-Series.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                  <a
                                    href="upload/cats/Pooja-Room-Series.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src="upload/cats/pooja-room-catalogue.png"
                                      alt=""
                                      width="600"
                                      height="800"
                                      title="Image Title"
                                    />
                                    <span className="gdlr-core-image-overlay gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                      <span className="gdlr-core-image-overlay-content">
                                        <span
                                          className="gdlr-core-portfolio-title gdlr-core-title-font"
                                          style={{
                                            fontSize: "23px",
                                            fontWeight: 600,
                                            letterSpacing: "0px",
                                            textTransform: "none",
                                          }}
                                        >
                                          <a
                                            href="upload/cats/Pooja-Room-Series.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Pooja Room Series
                                          </a>
                                        </span>
                                        <span
                                          className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font"
                                          style={{
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                          }}
                                        >
                                          {" "}
                                        </span>
                                      </span>
                                    </span>
                                  </a>
                                </div>
                              </a>
                            </div>
                            <div className="gdlr-core-item-list gdlr-core-gallery-column gdlr-core-column-15 gdlr-core-item-pdlr gdlr-core-item-mgb">
                              <a
                                href="upload/cats/Elevation-Series.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                  <a
                                    href="upload/cats/Elevation-Series.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src="upload/cats/elevation-catalogue.png"
                                      alt=""
                                      width="600"
                                      height="800"
                                      title="Image Title"
                                    />
                                    <span className="gdlr-core-image-overlay gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                      <span className="gdlr-core-image-overlay-content">
                                        <span
                                          className="gdlr-core-portfolio-title gdlr-core-title-font"
                                          style={{
                                            fontSize: "23px",
                                            fontWeight: 600,
                                            letterSpacing: "0px",
                                            textTransform: "none",
                                          }}
                                        >
                                          <a
                                            href="upload/cats/Elevation-Series.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Elevation Series
                                          </a>
                                        </span>
                                        <span
                                          className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font"
                                          style={{
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                          }}
                                        >
                                          {" "}
                                        </span>
                                      </span>
                                    </span>
                                  </a>
                                </div>
                              </a>
                            </div>
                            <div className="gdlr-core-item-list gdlr-core-gallery-column gdlr-core-column-15 gdlr-core-item-pdlr gdlr-core-item-mgb">
                              <a
                                href="upload/cats/Corridor-Sit-Out-Series.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                  <a
                                    href="upload/cats/Corridor-Sit-Out-Series.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src="upload/cats/corridor-sit-out-catalogue.png"
                                      alt=""
                                      width="600"
                                      height="800"
                                      title="Image Title"
                                    />
                                    <span className="gdlr-core-image-overlay gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                      <span className="gdlr-core-image-overlay-content">
                                        <span
                                          className="gdlr-core-portfolio-title gdlr-core-title-font"
                                          style={{
                                            fontSize: "23px",
                                            fontWeight: 600,
                                            letterSpacing: "0px",
                                            textTransform: "none",
                                          }}
                                        >
                                          <a
                                            href="upload/cats/Corridor-Sit-Out-Series.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Corridor Sit Out Series
                                          </a>
                                        </span>
                                        <span
                                          className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font"
                                          style={{
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                          }}
                                        >
                                          {" "}
                                        </span>
                                      </span>
                                    </span>
                                  </a>
                                </div>
                              </a>
                            </div>
                            <div className="gdlr-core-item-list gdlr-core-gallery-column gdlr-core-column-15 gdlr-core-item-pdlr gdlr-core-item-mgb">
                              <a
                                href="upload/cats/Plain-Colours.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                  <a
                                    href="upload/cats/Plain-Colours.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src="upload/cats/plain-catalogue.png"
                                      alt=""
                                      width="600"
                                      height="800"
                                      title="Image Title"
                                    />
                                    <span className="gdlr-core-image-overlay gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                      <span className="gdlr-core-image-overlay-content">
                                        <span
                                          className="gdlr-core-portfolio-title gdlr-core-title-font"
                                          style={{
                                            fontSize: "23px",
                                            fontWeight: 600,
                                            letterSpacing: "0px",
                                            textTransform: "none",
                                          }}
                                        >
                                          <a
                                            href="upload/cats/Plain-Colours.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Plain
                                          </a>
                                        </span>
                                        <span
                                          className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font"
                                          style={{
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                          }}
                                        >
                                          {" "}
                                        </span>
                                      </span>
                                    </span>
                                  </a>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <commons.Footer />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Gallery;
