import React, { Fragment } from "react";

const MobileHeader = ({ name }) => {
  return (
    <Fragment>
      <div className="arki-mobile-header-wrap">
        <div
          className="arki-mobile-header arki-header-background arki-style-slide arki-sticky-mobile-navigation"
          id="arki-mobile-header"
        >
          <div className="arki-mobile-header-container arki-container clearfix">
            <div className="arki-logo arki-item-pdlr">
              <div className="arki-logo-inner">
                <a className="arki-fixed-nav-logo" href="/">
                  <img
                    src="upload/white-logo.png"
                    alt=""
                    width="82"
                    height="25"
                    title="white-logo"
                    // srcSet="upload/white-logo.png 1x, upload/white-logox2.png 2x"
                  />
                </a>
                <a className="arki-orig-logo" href="/">
                  <img
                    src="upload/white-logo.png"
                    alt=""
                    width="164"
                    height="51"
                    title="white-logox2"
                  />
                </a>
              </div>
            </div>
            <div className="arki-mobile-menu-right">
              <div className="arki-top-search-wrap">
                <div className="arki-top-search-close"></div>

                <div className="arki-top-search-row">
                  <div className="arki-top-search-cell"></div>
                </div>
              </div>

              <div className="arki-mobile-menu">
                <a
                  className="arki-mm-menu-button arki-mobile-menu-button arki-mobile-button-hamburger"
                  href="#arki-mobile-menu"
                >
                  <span></span>
                </a>
                <div
                  className="arki-mm-menu-wrap arki-navigation-font"
                  id="arki-mobile-menu"
                  data-slide="right"
                >
                  <ul id="menu-main-navigation" className="m-menu">
                    <li
                      className={
                        name === "Home"
                          ? "menu-item menu-item-home current-menu-item current-menu-ancestor menu-item-has-children"
                          : "menu-item menu-item-has-children"
                      }
                    >
                      <a href="/" aria-current="page">
                        Home
                      </a>
                    </li>
                    <li
                      className={
                        name === "About"
                          ? "menu-item menu-item-home current-menu-item current-menu-ancestor menu-item-has-children"
                          : "menu-item menu-item-has-children"
                      }
                    >
                      <a href="/about">About Us</a>
                    </li>
                    <li
                      className={
                        name === "Categories"
                          ? "menu-item menu-item-home current-menu-item current-menu-ancestor menu-item-has-children"
                          : "menu-item menu-item-has-children"
                      }
                    >
                      <a href="/categories">Categories</a>
                    </li>
                    <li
                      className={
                        name === "Catalogue"
                          ? "menu-item menu-item-home current-menu-item current-menu-ancestor menu-item-has-children"
                          : "menu-item menu-item-has-children"
                      }
                    >
                      <a href="/catalogue">Catalogue</a>
                    </li>
                    <li
                      className={
                        name === "Contact"
                          ? "menu-item menu-item-home current-menu-item current-menu-ancestor menu-item-has-children"
                          : "menu-item menu-item-has-children"
                      }
                    >
                      <a href="/contact">Contact</a>
                    </li>
                    <li
                      className={
                        name === "Blog"
                          ? "menu-item menu-item-home current-menu-item current-menu-ancestor menu-item-has-children"
                          : "menu-item menu-item-has-children"
                      }
                    >
                      <a href="/blog">Blog</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="arki-mobile-header-wrap">
        <div
          className="arki-mobile-header arki-header-background arki-style-slide arki-sticky-mobile-navigation"
          id="arki-mobile-header"
        >
          <div className="arki-mobile-header-container arki-container clearfix">
            <div className="arki-logo arki-item-pdlr">
              <div className="arki-logo-inner">
                <a className="arki-fixed-nav-logo" href="/">
                  <img
                    src="upload/white-logo.png"
                    alt=""
                    width="82"
                    height="25"
                    title="white-logo"
                    srcset="upload/white-logo.png 1x, upload/white-logox2.png 2x"
                  />
                </a>
                <a className="arki-orig-logo" href="/">
                  <img
                    src="upload/white-logo.png"
                    alt=""
                    width="164"
                    height="51"
                    title="white-logox2"
                  />
                </a>
              </div>
            </div>
            <div className="arki-mobile-menu-right">
              <div className="arki-main-menu-search" id="arki-mobile-top-search">
                <i className="fa fa-search"></i>
              </div>
              <div className="arki-top-search-wrap">
                <div className="arki-top-search-close"></div>

                <div className="arki-top-search-row">
                  <div className="arki-top-search-cell"></div>
                </div>
              </div>

              <div className="arki-mobile-menu">
                <a
                  className="arki-mm-menu-button arki-mobile-menu-button arki-mobile-button-hamburger"
                  href="#arki-mobile-menu"
                >
                  <span></span>
                </a>
                <div
                  className="arki-mm-menu-wrap arki-navigation-font"
                  id="arki-mobile-menu"
                  data-slide="right"
                >
                  <ul className="m-menu mm-listview" id="menu-main-navigation">
                    <li className="menu-item menu-item-home current-menu-item current-menu-ancestor menu-item-has-children">
                      <a href="/" aria-current="page">
                        Home
                      </a>
                    </li>
                    <li className="menu-item menu-item-has-children">
                      <a href="interior-design.html">Services</a>
                    </li>
                    <li className="menu-item menu-item-has-children">
                      <a href="about-1.html">About</a>
                    </li>
                    <li className="menu-item menu-item-has-children">
                      <a href="#/">Pages</a>
                    </li>
                    <li className="menu-item menu-item-has-children">
                      <a href="portfolio-3-columns.html">Portfolio</a>
                    </li>
                    <li className="menu-item menu-item-has-children">
                      <a href="blog-full-right-sidebar.html">Blog</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Fragment>
  );
};

export default MobileHeader;
