import React, { Fragment } from "react";
import commons from "./common/commons";

const Home = () => {
  return (
    <Fragment>
      <div
        className="page-template-default page page-id-13775 theme-arki gdlr-core-body woocommerce-no-js arki-body arki-body-front arki-full arki-with-sticky-navigation arki-blockquote-style-3 gdlr-core-link-to-lightbox"
        data-home-url="/"
      >
        <commons.MobileHeader name="Home" />
        <div className="arki-body-outer-wrapper mm-page mm-slideout">
          <div className="arki-body-wrapper clearfix arki-with-transparent-header arki-with-frame">
            <commons.Header name="home" />
            <div className="arki-page-wrapper" id="arki-page-wrapper">
              <div className="gdlr-core-page-builder-body">
                <div
                  className="gdlr-core-pbf-wrapper"
                  style={{ padding: "0px 0px 0px 0px" }}
                >
                  <div
                    className="gdlr-core-pbf-background-wrap"
                    style={{ backgroundColor: "#191919" }}
                  ></div>
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                      <div className="gdlr-core-pbf-element">
                        <div
                          className="gdlr-core-revolution-slider-item gdlr-core-item-pdlr gdlr-core-item-pdb"
                          style={{ paddingBottom: "0px" }}
                        >
                          <p className="rs-p-wp-fix"></p>
                          <rs-module-wrap
                            id="rev_slider_3_1_wrapper"
                            data-source="gallery"
                            style={{
                              background: "transparent",
                              padding: 0,
                              margin: "0px auto",
                              marginTop: 0,
                              marginBottom: 0,
                            }}
                          >
                            <rs-module id="rev_slider_3_1" data-version="6.3.4">
                              <rs-slides>
                                <rs-slide
                                  data-key="rs-6"
                                  data-title="Slide"
                                  data-thumb="upload/hp1-slider-1-1.png"
                                  data-anim="ei:d;eo:d;t:fade;sl:7;"
                                  data-p2="01 Our Company"
                                >
                                  <img
                                    src="upload/hp1-slider-1-1.png"
                                    alt="imagee alt"
                                    title="hp1-slider-1"
                                    width="1933"
                                    height="1308"
                                    className="rev-slidebg"
                                    data-no-retina=""
                                  />

                                  <rs-layer
                                    id="slider-3-slide-6-layer-0"
                                    data-type="text"
                                    data-color="#ffffff"
                                    data-rsp_ch="on"
                                    data-xy="x:c;xo:456px,456px,0,0;yo:857px,857px,477px,312px;"
                                    data-text="w:normal;s:21,21,20,19;l:35,35,35,27;ls:3px,3px,3px,1px;a:left,left,center,center;"
                                    data-dim="w:246px,246px,246px,358px;"
                                    data-frame_1="st:2470;sp:470;sR:2470;"
                                    data-frame_999="o:0;st:w;sR:6060;"
                                    style={{ zIndex: 9, fontFamily: "Jost" }}
                                  >
                                    Creating A Lively Atmosphere With Our
                                    Designer Wall Tiles
                                  </rs-layer>

                                  <rs-layer
                                    id="slider-3-slide-6-layer-1"
                                    data-type="shape"
                                    data-rsp_ch="on"
                                    data-xy="x:c;xo:368px,368px,-70px,280px;yo:816px,816px,302px,295px;"
                                    data-text="w:normal;s:20,20,12,7;l:0,0,15,9;"
                                    data-dim="w:68px,68px,60px,22px;h:1px,1px,5px,3px;"
                                    data-vbility="t,t,f,f"
                                    data-frame_1="st:2140;sp:490;sR:2140;"
                                    data-frame_999="o:0;st:w;sR:6370;"
                                    style={{
                                      zIndex: 10,
                                      backgroundColor: "#ffffff",
                                    }}
                                  ></rs-layer>

                                  <rs-layer
                                    id="slider-3-slide-6-layer-3"
                                    data-type="shape"
                                    data-rsp_ch="on"
                                    data-xy="xo:1155px,1155px,672px,406px;yo:838px,838px,509px,316px;"
                                    data-text="w:normal;s:20,20,12,7;l:0,0,15,9;"
                                    data-dim="w:82px,82px,49px,30px;h:82px,82px,49px,30px;"
                                    data-border="bos:solid;boc:rgba(255, 255, 255, 0.21);bow:1px,1px,1px,1px;bor:41px,41px,41px,41px;"
                                    data-frame_1="st:2820;sp:460;sR:2820;"
                                    data-frame_999="o:0;st:w;sR:5720;"
                                    style={{ zIndex: "8" }}
                                  ></rs-layer>

                                  {window.innerWidth > 700 ? (
                                    <rs-layer
                                      // id="slider-3-slide-6-layer-5"
                                      // data-type="text"
                                      // data-rsp_ch="on"
                                      // data-xy="x:c;xo:463px,463px,0,0;yo:655px,655px,420px,272px;"
                                      // data-text="w:normal;s:43,43,20,19;l:57,57,57,29;ls:14px,14px,14px,5px;fw:500,500,500,600;a:left,left,center,center;"
                                      // data-dim="w:264px,264px,366px,358px;"
                                      // data-frame_0="x:50px;"
                                      // data-frame_1="st:1710;sp:490;sR:1710;"
                                      // data-frame_999="o:0;st:w;sR:6800;"
                                      // style={{ zIndex: 11, fontFamily: "Jost" }}
                                      id="slider-3-slide-25-layer-5"
                                      data-type="text"
                                      data-rsp_ch="on"
                                      data-xy="x:c;xo:463px,463px,0,0;yo:655px,655px,420px,272px;"
                                      data-text="w:normal;s:36,36,18,16;l:48,48,48,24;ls:4px,4px,4px,-5px;fw:500,500,500,600;a:left,left,center,center;"
                                      data-dim="w:264px,264px,366px,358px;"
                                      data-frame_0="x:50px;"
                                      data-frame_1="st:1710;sp:490;sR:1710;"
                                      data-frame_999="o:0;st:w;sR:6800;"
                                      style={{
                                        zIndex: 11,
                                        fontFamily: "Jost",
                                      }}
                                    >
                                      Redefining your home space!
                                    </rs-layer>
                                  ) : (
                                    <rs-layer
                                      id="slider-3-slide-6-layer-5"
                                      data-type="text"
                                      data-rsp_ch="on"
                                      data-xy="x:c;xo:463px,463px,0,0;yo:655px,655px,420px,272px;"
                                      data-text="w:normal;s:43,43,20,19;l:57,57,57,29;ls:14px,14px,14px,3px;fw:700,700,700,800;a:left,left,center,center;"
                                      data-dim="w:264px,264px,366px,358px;"
                                      data-frame_0="x:50px;"
                                      data-frame_1="st:1710;sp:490;sR:1710;"
                                      data-frame_999="o:0;st:w;sR:6800;"
                                      style={{ zIndex: 11, fontFamily: "Jost" }}
                                      // id="slider-3-slide-25-layer-5"
                                      // data-type="text"
                                      // data-rsp_ch="on"
                                      // data-xy="x:c;xo:463px,463px,0,0;yo:655px,655px,420px,272px;"
                                      // data-text="w:normal;s:36,36,18,16;l:48,48,48,24;ls:4px,4px,4px,-5px;fw:500,500,500,600;a:left,left,center,center;"
                                      // data-dim="w:264px,264px,366px,358px;"
                                      // data-frame_0="x:50px;"
                                      // data-frame_1="st:1710;sp:490;sR:1710;"
                                      // data-frame_999="o:0;st:w;sR:6800;"
                                      // style={{
                                      //   zIndex: 11,
                                      //   fontFamily: "Jost",
                                      // }}
                                    >
                                      Redefining your home space!
                                    </rs-layer>
                                  )}

                                  <rs-layer
                                    id="slider-3-slide-6-layer-7"
                                    data-type="text"
                                    data-color="#bbbbbb"
                                    data-rsp_ch="on"
                                    data-xy="xo:612px,612px,371px,228px;yo:282px,282px,170px,94px;"
                                    data-text="w:normal;s:370,370,224,138;l:300,300,182,112;ls:1,1,0,0;fw:700;"
                                    data-dim="minh:0px,0px,none,none;"
                                    data-frame_0="x:-100px,-100px,-60px,-37px;"
                                    data-frame_1="st:590;sp:870;sR:590;"
                                    data-frame_999="o:0;st:w;sR:7540;"
                                    style={{ zIndex: 15, fontFamily: "Jost" }}
                                  >
                                    {/* von */}
                                  </rs-layer>

                                  <a
                                    id="slider-3-slide-6-layer-8"
                                    className="rs-layer rev-btn"
                                    href="/categories"
                                    target="_self"
                                    data-type="button"
                                    data-rsp_ch="on"
                                    data-xy="x:c;xo:-41px,-41px,0,3px;yo:690px,690px,561px,372px;"
                                    data-text="w:normal;s:20,20,19,18;l:20,20,12,10;ls:2px,2px,2px,1px;fw:600;a:left,left,center,center;"
                                    data-dim="w:auto,auto,261px,285px;h:20px,20px,52px,44px;minh:0px,0px,none,none;"
                                    data-padding="t:0,0,17,17;r:0,0,17,28;b:0,0,17,17;l:0,0,18,28;"
                                    data-frame_1="st:1360;sR:1360;"
                                    data-frame_999="o:0;st:w;sR:7340;"
                                    data-frame_hover="c:#aaa;bor:0px,0px,0px,0px;bri:120%;"
                                    style={{ zIndex: 13, fontFamily: "Jost" }}
                                  >
                                    View All Works
                                    <img
                                      src="upload/arrow_right.png"
                                      alt=""
                                      className="arrow_class"
                                    />
                                  </a>

                                  <rs-layer
                                    id="slider-3-slide-6-layer-9"
                                    data-type="text"
                                    data-color="#fff"
                                    data-rsp_ch="on"
                                    data-xy="xo:394px,394px,239px,147px;yo:176px,176px,106px,55px;"
                                    data-text="w:normal;s:230,230,224,138;l:300,300,182,112;ls:1,1,0,0;fw:400;"
                                    data-dim="minh:0px,0px,none,none;"
                                    data-frame_0="y:-100px,-100px,-60px,-37px;"
                                    data-frame_1="y:-1px,-1px,0px,0px;sp:960;"
                                    data-frame_999="o:0;st:w;sR:8040;"
                                    style={{ zIndex: 16, fontFamily: "Jost" }}
                                  >
                                    Vevon
                                    <br />
                                  </rs-layer>

                                  <rs-layer
                                    id="slider-3-slide-6-layer-10"
                                    data-type="shape"
                                    data-rsp_ch="on"
                                    data-xy="x:l,l,c,c;xo:529px,529px,0,508px;yo:674px,674px,579px,252px;"
                                    data-text="w:normal;s:20,20,12,7;l:0,0,15,9;"
                                    data-dim="w:195px,195px,118px,72px;h:65px,65px,39px,24px;"
                                    data-vbility="t,t,t,f"
                                    data-frame_1="st:1530;sR:1530;"
                                    data-frame_999="o:0;st:w;sR:7170;"
                                    style={{
                                      zIndex: 12,
                                      backgroundColor: "#000000",
                                    }}
                                  ></rs-layer>

                                  <rs-layer
                                    id="slider-3-slide-6-layer-21"
                                    data-type="shape"
                                    data-rsp_ch="on"
                                    data-xy="x:c;xo:-107px,-107px,0,330px;yo:466px,466px,322px,295px;"
                                    data-text="w:normal;s:20,20,12,7;l:0,0,15,9;"
                                    data-dim="w:1px,1px,60px,22px;h:40px,40px,5px,3px;"
                                    data-vbility="t,t,f,f"
                                    data-frame_0="y:30px,30px,18px,11px;"
                                    data-frame_1="st:1170;sR:1170;"
                                    data-frame_999="o:0;st:w;sR:7530;"
                                    style={{
                                      zIndex: 14,
                                      backgroundColor: "#ffffff",
                                    }}
                                  ></rs-layer>
                                </rs-slide>
                                <rs-slide
                                  data-key="rs-25"
                                  data-title="Slide"
                                  data-thumb="upload/slider-1-2.png"
                                  data-anim="ei:d;eo:d;t:fade;sl:7;"
                                  data-p2="01 Our Company"
                                >
                                  <img
                                    src="upload/slider-1-2.png"
                                    alt="imagee alt"
                                    title="slider-1-2"
                                    width="1900"
                                    height="1400"
                                    className="rev-slidebg"
                                    data-no-retina=""
                                  />

                                  <rs-layer
                                    id="slider-3-slide-25-layer-0"
                                    data-type="text"
                                    data-color="#f4f4f4"
                                    data-rsp_ch="on"
                                    data-xy="x:c;xo:456px,456px,0,0;yo:857px,857px,477px,312px;"
                                    data-text="w:normal;s:21,21,20,19;l:35,35,35,27;ls:3px,3px,3px,1px;a:left,left,center,center;"
                                    data-dim="w:246px,246px,246px,358px;"
                                    data-frame_1="st:2470;sp:470;sR:2470;"
                                    data-frame_999="o:0;st:w;sR:6060;"
                                    style={{ zIndex: 9, fontFamily: "Jost" }}
                                  >
                                    Unique and Innovative Design Ideas To Match
                                    Your Vision
                                  </rs-layer>

                                  <rs-layer
                                    id="slider-3-slide-25-layer-1"
                                    data-type="shape"
                                    data-rsp_ch="on"
                                    data-xy="x:c;xo:368px,368px,-70px,280px;yo:816px,816px,302px,295px;"
                                    data-text="w:normal;s:20,20,12,7;l:0,0,15,9;"
                                    data-dim="w:68px,68px,60px,22px;h:1px,1px,5px,3px;"
                                    data-vbility="t,t,f,f"
                                    data-frame_1="st:2140;sp:490;sR:2140;"
                                    data-frame_999="o:0;st:w;sR:6370;"
                                    style={{
                                      zIndex: 10,
                                      backgroundColor: "#ffffff",
                                    }}
                                  ></rs-layer>

                                  <rs-layer
                                    id="slider-3-slide-25-layer-3"
                                    data-type="shape"
                                    data-rsp_ch="on"
                                    data-xy="xo:1155px,1155px,672px,406px;yo:838px,838px,509px,316px;"
                                    data-text="w:normal;s:20,20,12,7;l:0,0,15,9;"
                                    data-dim="w:82px,82px,49px,30px;h:82px,82px,49px,30px;"
                                    data-border="bos:solid;boc:rgba(255, 255, 255, 0.21);bow:1px,1px,1px,1px;bor:41px,41px,41px,41px;"
                                    data-frame_1="st:2820;sp:460;sR:2820;"
                                    data-frame_999="o:0;st:w;sR:5720;"
                                    style={{ zIndex: "8" }}
                                  ></rs-layer>

                                  {window.innerWidth > 700 ? (
                                    <rs-layer
                                      id="slider-3-slide-25-layer-5"
                                      data-type="text"
                                      data-rsp_ch="on"
                                      data-xy="x:c;xo:463px,463px,0,0;yo:655px,655px,420px,272px;"
                                      data-text="w:normal;s:36,36,18,16;l:48,48,48,24;ls:4px,4px,4px,-5px;fw:500,500,500,600;a:left,left,center,center;"
                                      data-dim="w:264px,264px,366px,358px;"
                                      data-frame_0="x:50px;"
                                      data-frame_1="st:1710;sp:490;sR:1710;"
                                      data-frame_999="o:0;st:w;sR:6800;"
                                      style={{
                                        zIndex: 11,
                                        fontFamily: "Jost",
                                      }}
                                    >
                                      Decorative Tiles beyond ordinary!
                                    </rs-layer>
                                  ) : (
                                    <rs-layer
                                      id="slider-3-slide-6-layer-5"
                                      data-type="text"
                                      data-rsp_ch="on"
                                      data-xy="x:c;xo:463px,463px,0,0;yo:655px,655px,420px,272px;"
                                      data-text="w:normal;s:43,43,20,19;l:57,57,57,29;ls:14px,14px,14px,2px;fw:700,700,700,800;a:left,left,center,center;"
                                      data-dim="w:264px,264px,366px,358px;"
                                      data-frame_0="x:50px;"
                                      data-frame_1="st:1710;sp:490;sR:1710;"
                                      data-frame_999="o:0;st:w;sR:6800;"
                                      style={{ zIndex: 11, fontFamily: "Jost" }}
                                    >
                                      Decorative Tiles beyond ordinary!
                                    </rs-layer>
                                  )}

                                  <rs-layer
                                    id="slider-3-slide-25-layer-7"
                                    data-type="text"
                                    data-color="#bbbbbb"
                                    data-rsp_ch="on"
                                    data-xy="xo:612px,612px,371px,228px;yo:282px,282px,170px,94px;"
                                    data-text="w:normal;s:370,370,224,138;l:300,300,182,112;ls:1,1,0,0;fw:700;"
                                    data-dim="minh:0px,0px,none,none;"
                                    data-frame_0="x:-100px,-100px,-60px,-37px;"
                                    data-frame_1="st:590;sp:870;sR:590;"
                                    data-frame_999="o:0;st:w;sR:7540;"
                                    style={{
                                      zIndex: 15,
                                      fontFamily: "Jost",
                                    }}
                                  >
                                    {/* von */}
                                  </rs-layer>

                                  <a
                                    id="slider-3-slide-25-layer-8"
                                    className="rs-layer rev-btn"
                                    href="/categories"
                                    target="_self"
                                    data-type="button"
                                    data-rsp_ch="on"
                                    data-xy="x:c;xo:-41px,-41px,0,3px;yo:690px,690px,561px,372px;"
                                    data-text="w:normal;s:20,20,19,18;l:20,20,12,10;ls:2px,2px,2px,1px;fw:600;a:left,left,center,center;"
                                    data-dim="w:auto,auto,261px,285px;h:20px,20px,52px,44px;minh:0px,0px,none,none;"
                                    data-padding="t:0,0,17,17;r:0,0,17,28;b:0,0,17,17;l:0,0,18,28;"
                                    data-frame_1="st:1360;sR:1360;"
                                    data-frame_999="o:0;st:w;sR:7340;"
                                    data-frame_hover="c:#aaa;bor:0px,0px,0px,0px;bri:120%;"
                                    style={{ zIndex: 13, fontFamily: "Jost" }}
                                  >
                                    View All Works
                                    <img
                                      src="upload/arrow_right.png"
                                      alt=""
                                      className="arrow_class"
                                    />
                                  </a>

                                  <rs-layer
                                    id="slider-3-slide-25-layer-9"
                                    data-type="text"
                                    data-color="#fff"
                                    data-rsp_ch="on"
                                    data-xy="xo:394px,394px,239px,147px;yo:176px,176px,106px,55px;"
                                    data-text="w:normal;s:230,230,224,138;l:300,300,182,112;ls:1,1,0,0;fw:400;"
                                    data-dim="minh:0px,0px,none,none;"
                                    data-frame_0="y:-100px,-100px,-60px,-37px;"
                                    data-frame_1="y:-1px,-1px,0px,0px;sp:960;"
                                    data-frame_999="o:0;st:w;sR:8040;"
                                    style={{
                                      zIndex: 16,
                                      fontFamily: "Jost",
                                    }}
                                  >
                                    Vevon
                                    <br />
                                  </rs-layer>

                                  <rs-layer
                                    id="slider-3-slide-25-layer-10"
                                    data-type="shape"
                                    data-rsp_ch="on"
                                    data-xy="x:l,l,c,c;xo:529px,529px,0,508px;yo:674px,674px,579px,252px;"
                                    data-text="w:normal;s:20,20,12,7;l:0,0,15,9;"
                                    data-dim="w:195px,195px,118px,72px;h:65px,65px,39px,24px;"
                                    data-vbility="t,t,t,f"
                                    data-frame_1="st:1530;sR:1530;"
                                    data-frame_999="o:0;st:w;sR:7170;"
                                    style={{
                                      zIndex: 12,
                                      backgroundColor: "#000000",
                                    }}
                                  ></rs-layer>

                                  <rs-layer
                                    id="slider-3-slide-25-layer-21"
                                    data-type="shape"
                                    data-rsp_ch="on"
                                    data-xy="x:c;xo:-107px,-107px,0,330px;yo:466px,466px,322px,295px;"
                                    data-text="w:normal;s:20,20,12,7;l:0,0,15,9;"
                                    data-dim="w:1px,1px,60px,22px;h:40px,40px,5px,3px;"
                                    data-vbility="t,t,f,f"
                                    data-frame_0="y:30px,30px,18px,11px;"
                                    data-frame_1="st:1170;sR:1170;"
                                    data-frame_999="o:0;st:w;sR:7530;"
                                    style={{
                                      zIndex: 14,
                                      backgroundColor: "#ffffff",
                                    }}
                                  ></rs-layer>
                                </rs-slide>
                              </rs-slides>
                              <rs-static-layers>
                                <rs-layer
                                  id="slider-3-slide-3-layer-15"
                                  className="rs-layer-static"
                                  data-type="image"
                                  data-rsp_ch="on"
                                  data-xy="xo:67px,67px,40px,24px;yo:911px,911px,641px,395px;"
                                  data-text="w:normal;s:20,20,12,7;l:0,0,15,9;"
                                  data-dim="w:38px,38px,22px,13px;h:53px,53px,31px,19px;"
                                  data-vbility="t,t,t,f"
                                  data-actions="o:click;a:jumptoslide;slide:previous;"
                                  data-onslides="s:1;"
                                  data-frame_999="o:0;st:w;"
                                  data-frame_hover="o:0.5;"
                                  style={{ zIndex: 10, cursor: "pointer" }}
                                >
                                  <img
                                    src="upload/left-arrow.png"
                                    alt="imagee alt"
                                    data-no-retina=""
                                  />
                                </rs-layer>

                                <rs-layer
                                  id="slider-3-slide-3-layer-16"
                                  className="rs-layer-static"
                                  data-type="shape"
                                  data-rsp_ch="on"
                                  data-xy="y:b;"
                                  data-text="w:normal;s:20,20,12,7;l:0,0,15,9;"
                                  data-dim="w:254px,254px,154px,95px;h:140px,140px,85px,52px;"
                                  data-vbility="t,t,t,f"
                                  data-onslides="s:1;"
                                  data-frame_999="o:0;st:w;"
                                  style={{
                                    zIndex: 8,
                                    backgroundColor: "#161616",
                                  }}
                                ></rs-layer>

                                <rs-layer
                                  id="slider-3-slide-3-layer-17"
                                  className="rs-layer-static"
                                  data-type="image"
                                  data-rsp_ch="on"
                                  data-xy="xo:160px,160px,97px,59px;yo:911px,911px,641px,395px;"
                                  data-text="w:normal;s:20,20,12,7;l:0,0,15,9;"
                                  data-dim="w:38px,38px,22px,13px;h:53px,53px,31px,19px;"
                                  data-vbility="t,t,t,f"
                                  data-actions="o:click;a:jumptoslide;slide:next;"
                                  data-onslides="s:1;"
                                  data-frame_999="o:0;st:w;"
                                  data-frame_hover="o:0.5;"
                                  style={{ zIndex: 11, cursor: "pointer" }}
                                >
                                  <img
                                    src="upload/right-arrow.png"
                                    alt="imagee alt"
                                    data-no-retina=""
                                  />
                                </rs-layer>

                                <rs-layer
                                  id="slider-3-slide-3-layer-18"
                                  className="rs-layer-static"
                                  data-type="shape"
                                  data-rsp_ch="on"
                                  data-xy="x:c;xo:-282px,-282px,0,0;yo:936px,936px,322px,295px;"
                                  data-text="w:normal;s:20,20,12,7;l:0,0,15,9;"
                                  data-dim="w:68px,68px,60px,22px;h:1px,1px,5px,3px;"
                                  data-vbility="t,t,f,f"
                                  data-onslides="s:1;"
                                  data-frame_1="st:660;sR:660;"
                                  data-frame_999="o:0;st:w;sR:8040;"
                                  style={{
                                    zIndex: 13,
                                    backgroundColor: "#ffffff",
                                  }}
                                ></rs-layer>
                              </rs-static-layers>
                            </rs-module>
                          </rs-module-wrap>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="gdlr-core-pbf-wrapper"
                  style={{ padding: "140px 0px 130px 0px" }}
                  id="gdlr-core-wrapper-1"
                >
                  <div
                    className="gdlr-core-pbf-background-wrap"
                    style={{ backgroundColor: "#fff" }}
                  ></div>
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first"
                        id="gdlr-core-column-35530"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          style={{
                            margin: "0px 0px 0px 0px",
                            padding: "0px 0px 0px 0px",
                          }}
                        >
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-image-item gdlr-core-item-pdb gdlr-core-left-align gdlr-core-item-pdlr">
                                <div
                                  className="gdlr-core-image-item-wrap gdlr-core-media-image gdlr-core-image-item-style-rectangle"
                                  style={{
                                    borderWidth: "0px",
                                    maxWidth: "172px",
                                  }}
                                >
                                  <img
                                    src="upload/icon01-1.png"
                                    alt=""
                                    width="450"
                                    height="225"
                                    title="icon01"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                style={{ paddingBottom: "20px" }}
                              >
                                <div className="gdlr-core-title-item-title-wrap">
                                  <h3
                                    className="gdlr-core-title-item-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                      color: "#0000000",
                                    }}
                                  >
                                    CREATIVE TEAM
                                    <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align gdlr-core-no-p-space"
                                style={{ paddingBottom: "60px" }}
                              >
                                <div
                                  className="gdlr-core-text-box-item-content"
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: 400,
                                    textTransform: "none",
                                    color: "#000",
                                  }}
                                >
                                  <p>
                                    Our professional team is an expert in
                                    bringing up vibrant ideas to serve your
                                    modern vision
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-20"
                        id="gdlr-core-column-77827"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          style={{
                            margin: "0px 0px 0px 0px",
                            padding: "0px 0px 0px 0px",
                          }}
                        >
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-image-item gdlr-core-item-pdb gdlr-core-left-align gdlr-core-item-pdlr">
                                <div
                                  className="gdlr-core-image-item-wrap gdlr-core-media-image gdlr-core-image-item-style-rectangle"
                                  style={{
                                    borderWidth: "0px",
                                    maxWidth: "172px",
                                  }}
                                >
                                  <img
                                    src="upload/icon02-1.png"
                                    alt=""
                                    width="450"
                                    height="222"
                                    title="icon02"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                style={{ paddingBottom: "20px" }}
                              >
                                <div className="gdlr-core-title-item-title-wrap">
                                  <h3
                                    className="gdlr-core-title-item-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                      color: "#000",
                                    }}
                                  >
                                    ARTISTIC WALL TILES
                                    <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align gdlr-core-no-p-space"
                                style={{ paddingBottom: "60px" }}
                              >
                                <div
                                  className="gdlr-core-text-box-item-content"
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: 400,
                                    textTransform: "none",
                                    color: "#000",
                                  }}
                                >
                                  <p>
                                    Our designs exhibit a fine touch of art that
                                    potentially glorify the overall look of your
                                    walls.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-20"
                        id="gdlr-core-column-54707"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          style={{
                            margin: "0px 0px 0px 0px",
                            padding: "0px 0px 0px 0px",
                          }}
                        >
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-image-item gdlr-core-item-pdb gdlr-core-left-align gdlr-core-item-pdlr">
                                <div
                                  className="gdlr-core-image-item-wrap gdlr-core-media-image gdlr-core-image-item-style-rectangle"
                                  style={{
                                    borderWidth: "0px",
                                    maxWidth: "172px",
                                  }}
                                >
                                  <img
                                    src="upload/icon03-1.png"
                                    alt=""
                                    width="450"
                                    height="225"
                                    title="icon03"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                style={{ paddingBottom: "20px" }}
                              >
                                <div className="gdlr-core-title-item-title-wrap">
                                  <h3
                                    className="gdlr-core-title-item-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "24px",
                                      fontWeight: 500,
                                      textTransform: "none",
                                      color: "#000",
                                    }}
                                  >
                                    DETAIL IN DESIGNS
                                    <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align gdlr-core-no-p-space"
                                style={{ paddingBottom: "60px" }}
                              >
                                <div
                                  className="gdlr-core-text-box-item-content"
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: 400,
                                    textTransform: "none",
                                    color: "#000",
                                  }}
                                >
                                  <p>
                                    Carving through details is what gives our
                                    designs a feeling of fulfillment. It is the
                                    actual backbone of our craft!
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="gdlr-core-pbf-wrapper"
                  style={{ padding: "0px 0px 0px 0px" }}
                >
                  <div
                    className="gdlr-core-pbf-background-wrap"
                    style={{ backgroundColor: "#7c7c7c" }}
                  ></div>
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full-no-space">
                      <div className="gdlr-core-pbf-element">
                        <div
                          className="gdlr-core-portfolio-item gdlr-core-item-pdb clearfix gdlr-core-portfolio-item-style-modern3 gdlr-core-item-pdlr"
                          style={{ paddingBottom: "0px" }}
                          id="gdlr-core-portfolio-76868"
                        >
                          <div
                            className="gdlr-core-flexslider flexslider gdlr-core-js-2"
                            data-type="carousel"
                            data-column="4"
                            data-move="1"
                            data-nav="navigation-bottom"
                            data-nav-parent="self"
                            data-disable-autoslide="1"
                          >
                            <div
                              className="gdlr-core-flexslider-custom-nav gdlr-core-style-navigation-bottom gdlr-core-center-align"
                              style={{ marginTop: "-115px" }}
                            >
                              <i
                                className="ion-ios-arrow-thin-left flex-prev"
                                style={{
                                  color: "#d1d1d1",
                                  backgroundColor: "#0f0f0f",
                                  padding: "31px 45px 31px 45px",
                                  marginRight: "0px",
                                  fontSize: "52px",
                                }}
                              ></i>
                              <i
                                className="ion-ios-arrow-thin-right flex-next"
                                style={{
                                  color: "#d1d1d1",
                                  backgroundColor: "#0f0f0f",
                                  padding: "31px 45px 31px 45px",
                                  fontSize: "52px",
                                }}
                              ></i>
                            </div>
                            <ul className="slides">
                              <li className="gdlr-core-item-mglr">
                                <commons.Link
                                  to="/category"
                                  state={{
                                    category: "Glossy Tiles",
                                  }}
                                >
                                  <div className="gdlr-core-portfolio-modern3">
                                    <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image gdlr-core-style-custom2">
                                      <div className="gdlr-core-portfolio-thumbnail-image-wrap gdlr-core-zoom-on-hover">
                                        <img
                                          src="upload/home/categories/home-glossy-tiles.png"
                                          alt="imagee alt"
                                          width="700"
                                          height="990"
                                          title="home-glossy-tiles"
                                        />
                                        <span className="gdlr-core-image-overlay gdlr-core-portfolio-overlay gdlr-core-custom">
                                          <span className="gdlr-core-image-overlay-content">
                                            <span className="gdlr-core-portfolio-title gdlr-core-title-font">
                                              <commons.Link
                                                to="/category"
                                                state={{
                                                  category: "Glossy Tiles",
                                                }}
                                              >
                                                Glossy Tiles
                                              </commons.Link>
                                            </span>
                                            <span className="gdlr-core-portfolio-caption">
                                              Tiles
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </commons.Link>
                              </li>
                              <li className="gdlr-core-item-mglr">
                                <commons.Link
                                  to="/category"
                                  state={{
                                    category: "Kitchen Series",
                                  }}
                                >
                                  <div className="gdlr-core-portfolio-modern3">
                                    <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image gdlr-core-style-custom2">
                                      <div className="gdlr-core-portfolio-thumbnail-image-wrap gdlr-core-zoom-on-hover">
                                        <img
                                          src="upload/home/categories/home-kitchen-series.png"
                                          alt=""
                                          width="700"
                                          height="990"
                                          title="home-kitchen-series"
                                        />
                                        <span className="gdlr-core-image-overlay gdlr-core-portfolio-overlay gdlr-core-custom">
                                          <span className="gdlr-core-image-overlay-content">
                                            <span className="gdlr-core-portfolio-title gdlr-core-title-font">
                                              <commons.Link
                                                to="/category"
                                                state={{
                                                  category: "Kitchen Series",
                                                }}
                                              >
                                                Kitchen Series
                                              </commons.Link>
                                            </span>
                                            <span className="gdlr-core-portfolio-caption">
                                              Tiles
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </commons.Link>
                              </li>

                              <li className="gdlr-core-item-mglr">
                                <commons.Link
                                  to="/category"
                                  state={{
                                    category: "Pooja Room Series",
                                  }}
                                >
                                  <div className="gdlr-core-portfolio-modern3">
                                    <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image gdlr-core-style-custom2">
                                      <div className="gdlr-core-portfolio-thumbnail-image-wrap gdlr-core-zoom-on-hover">
                                        <img
                                          src="upload/home/categories/home-pooja-room.png"
                                          alt=""
                                          width="700"
                                          height="990"
                                          title="home-pooja-room"
                                        />
                                        <span className="gdlr-core-image-overlay gdlr-core-portfolio-overlay gdlr-core-custom">
                                          <span className="gdlr-core-image-overlay-content">
                                            <span className="gdlr-core-portfolio-title gdlr-core-title-font">
                                              <commons.Link
                                                to="/category"
                                                state={{
                                                  category: "Pooja Room Series",
                                                }}
                                              >
                                                Pooja Room Series
                                              </commons.Link>
                                            </span>
                                            <span className="gdlr-core-portfolio-caption">
                                              Titles
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </commons.Link>
                              </li>

                              <li className="gdlr-core-item-mglr">
                                <commons.Link
                                  to="/category"
                                  state={{
                                    category: "Elevation Series",
                                  }}
                                >
                                  <div className="gdlr-core-portfolio-modern3">
                                    <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image gdlr-core-style-custom2">
                                      <div className="gdlr-core-portfolio-thumbnail-image-wrap gdlr-core-zoom-on-hover">
                                        <img
                                          src="upload/iStock-904390980-700x990.jpg"
                                          alt=""
                                          width="700"
                                          height="990"
                                          title="iStock-904390980"
                                        />
                                        <span className="gdlr-core-image-overlay gdlr-core-portfolio-overlay gdlr-core-custom">
                                          <span className="gdlr-core-image-overlay-content">
                                            <span className="gdlr-core-portfolio-title gdlr-core-title-font">
                                              <commons.Link
                                                to="/category"
                                                state={{
                                                  category: "Elevation Series",
                                                }}
                                              >
                                                Elevation Series
                                              </commons.Link>
                                            </span>
                                            <span className="gdlr-core-portfolio-caption">
                                              Tiles
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </commons.Link>
                              </li>

                              <li className="gdlr-core-item-mglr">
                                <commons.Link
                                  to="/category"
                                  state={{
                                    category: "Corridor Sit Out Series",
                                  }}
                                >
                                  <div className="gdlr-core-portfolio-modern3">
                                    <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image gdlr-core-style-custom2">
                                      <div className="gdlr-core-portfolio-thumbnail-image-wrap gdlr-core-zoom-on-hover">
                                        <img
                                          src="upload/home/categories/home-corridor-sit-out.png"
                                          alt=""
                                          width="700"
                                          height="990"
                                          title="home-corridor-sit-out"
                                        />
                                        <span className="gdlr-core-image-overlay gdlr-core-portfolio-overlay gdlr-core-custom">
                                          <span className="gdlr-core-image-overlay-content">
                                            <span className="gdlr-core-portfolio-title gdlr-core-title-font">
                                              <commons.Link
                                                to="/category"
                                                state={{
                                                  category:
                                                    "Corridor Sit Out Series",
                                                }}
                                              >
                                                Corridor Sit Out Series
                                              </commons.Link>
                                            </span>
                                            <span className="gdlr-core-portfolio-caption">
                                              Tiles
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </commons.Link>
                              </li>

                              <li className="gdlr-core-item-mglr">
                                <commons.Link
                                  to="/category"
                                  state={{
                                    category: "Plain",
                                  }}
                                >
                                  <div className="gdlr-core-portfolio-modern3">
                                    <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image gdlr-core-style-custom2">
                                      <div className="gdlr-core-portfolio-thumbnail-image-wrap gdlr-core-zoom-on-hover">
                                        <img
                                          src="upload/home/categories/home-plain.png"
                                          alt="imagee alt"
                                          width="700"
                                          height="990"
                                          title="home-plain"
                                        />
                                        <span className="gdlr-core-image-overlay gdlr-core-portfolio-overlay gdlr-core-custom">
                                          <span className="gdlr-core-image-overlay-content">
                                            <span className="gdlr-core-portfolio-title gdlr-core-title-font">
                                              <commons.Link
                                                to="/category"
                                                state={{
                                                  category: "Plain",
                                                }}
                                              >
                                                Plain
                                              </commons.Link>
                                            </span>
                                            <span className="gdlr-core-portfolio-caption">
                                              Tiles
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </commons.Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="gdlr-core-pbf-wrapper"
                  style={{ padding: "120px 0px 100px 0px", marginTop: 0 }}
                  id="gdlr-core-wrapper-2"
                >
                  <div
                    className="gdlr-core-pbf-background-wrap"
                    style={{ backgroundColor: "#171717" }}
                  >
                    <div
                      className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                      style={{
                        backgroundImage: `url(upload/bg-hp01-1.png)`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                      data-parallax-speed="0"
                    ></div>
                  </div>
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first"
                        id="gdlr-core-column-60191"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          style={{ padding: "0px 110px 0px 0px" }}
                        >
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align">
                                <div
                                  className="gdlr-core-divider-container"
                                  style={{ maxWidth: "15px" }}
                                >
                                  <div
                                    className="gdlr-core-divider-line gdlr-core-skin-divider"
                                    style={{ borderColor: "#ffffff" }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                style={{ paddingBottom: "20px" }}
                              >
                                <div className="gdlr-core-title-item-title-wrap">
                                  <img
                                    src="upload/home-1.png"
                                    alt="imagee alt"
                                    className="gdlr-core-title-item-title gdlr-core-skin-title"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align"
                                style={{
                                  marginBottom: "0px",
                                  marginLeft: "-100px",
                                }}
                              >
                                <div
                                  className="gdlr-core-divider-container"
                                  style={{ maxWidth: "200px" }}
                                >
                                  <div
                                    className="gdlr-core-divider-line gdlr-core-skin-divider"
                                    style={{
                                      borderColor: "#ffffff",
                                      borderRadius: "0px",
                                      MozBorderRadius: "0px",
                                      WebkitBorderRadius: "0px",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-30"
                        id="gdlr-core-column-3487"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          style={{ padding: "35px 0px 120px 0px" }}
                        >
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                style={{ paddingBottom: "25px" }}
                              >
                                <h3
                                  className="gdlr-core-title-item-title gdlr-core-skin-title"
                                  style={{
                                    fontSize: "49px",
                                    fontWeight: 500,
                                    letterSpacing: "3px",
                                    textTransform: "none",
                                    color: "#000",
                                  }}
                                >
                                  FULFILLING CUSTOMER NEEDS IN THE BEST WAY
                                  POSSIBLE!
                                  <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                </h3>

                                <div
                                  className="gdlr-core-text-box-item-content"
                                  style={{
                                    fontSize: "20px",
                                    lineHeight: 1.5,
                                    fontWeight: 400,
                                    letterSpacing: "1.8px",
                                    textTransform: "none",
                                    color: "#000",
                                  }}
                                >
                                  <p>
                                    We are constantly inspired to create
                                    something new in order to meet the dynamic
                                    needs of our customers. Watching people’s
                                    faces light up when they see the final
                                    output of their interior spaces gives us
                                    immense pleasure. From Contemporary to
                                    Traditional, Commercial to Residential,
                                    Vevon’s glorious collection elevates every
                                    interior beyond elegance.
                                  </p>
                                  <p>
                                    Several integral tiles’ manufacturing
                                    equipment is housed by the company which
                                    includes high capacity press, glazing
                                    machinery, digital printers, and the first
                                    kind Robo-Packing machine. The inventive
                                    tile motifs in stunning colors are poised as
                                    exclusive to each customer and quite
                                    personal, instantly upgrading any home or
                                    business and pushing us to continuously
                                    deliver new looks and innovative features.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                style={{ paddingBottom: "0px" }}
                              >
                                <a
                                  className="gdlr-core-button gdlr-core-button-transparent gdlr-core-left-align gdlr-core-button-with-border"
                                  href="/about"
                                  id="gdlr-core-button-id-2193"
                                >
                                  <span
                                    className="gdlr-core-content"
                                    style={{ color: "#000" }}
                                  >
                                    Learn More
                                  </span>
                                  <i className="gdlr-core-pos-right ion-ios-arrow-thin-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="gdlr-core-pbf-wrapper"
                  style={{ padding: "165px 0px 110px 0px" }}
                  id="gdlr-core-wrapper-3"
                >
                  <div
                    className="gdlr-core-pbf-background-wrap"
                    style={{ backgroundColor: "#0c0c0c" }}
                  >
                    <div
                      className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                      style={{
                        backgroundImage: ` url(upload/bg-hp-down01.png)`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "top center",
                      }}
                      data-parallax-speed="0"
                    ></div>
                  </div>
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first"
                        id="gdlr-core-column-61081"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          style={{
                            margin: "0px 0px 0px -100px",
                            paddingRight: "0px",
                            paddingLeft: "0px",
                          }}
                        >
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-image-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-item-pdlr"
                                style={{ paddingBottom: "0px" }}
                              >
                                <div
                                  className="gdlr-core-image-item-wrap gdlr-core-media-image gdlr-core-image-item-style-rectangle"
                                  style={{
                                    borderWidth: "0px",
                                    margin: "0px 0px 0px 0px",
                                  }}
                                >
                                  <img
                                    src="upload/home-category.png"
                                    alt=""
                                    width="1400"
                                    height="984"
                                    title="img building"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-30"
                        id="gdlr-core-column-77692"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          style={{ paddingTop: "160px", paddingLeft: "65px" }}
                        >
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align"
                                style={{ marginBottom: "50px" }}
                              >
                                <div
                                  className="gdlr-core-divider-container"
                                  style={{ maxWidth: "15px" }}
                                >
                                  <div
                                    className="gdlr-core-divider-line gdlr-core-skin-divider"
                                    style={{
                                      borderColor: "#000",
                                      borderRadius: "0px",
                                      MozBorderRadius: "0px",
                                      WebkitBorderRadius: "0px",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                style={{ paddingBottom: "55px" }}
                              >
                                <div className="gdlr-core-title-item-title-wrap">
                                  <h3
                                    className="gdlr-core-title-item-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "60px",
                                      fontWeight: 500,
                                      letterSpacing: "3px",
                                      textTransform: "none",
                                      color: "#000",
                                    }}
                                  >
                                    Our Categories.
                                    <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-style-1"
                                style={{ paddingBottom: "40px" }}
                              >
                                <ul>
                                  <li
                                    className="gdlr-core-skin-divider clearfix"
                                    style={{ marginBottom: "28px" }}
                                  >
                                    <div className="gdlr-core-icon-list-content-wrap">
                                      <span
                                        className="gdlr-core-icon-list-content"
                                        style={{
                                          color: "#000",
                                          fontSize: "16px",
                                          fontWeight: 500,
                                          textTransform: "uppercase",
                                          letterSpacing: "9px",
                                        }}
                                      >
                                        /Glossy Tiles
                                      </span>
                                    </div>
                                  </li>
                                  <li
                                    className="gdlr-core-skin-divider clearfix"
                                    style={{ marginBottom: "28px" }}
                                  >
                                    <div className="gdlr-core-icon-list-content-wrap">
                                      <span
                                        className="gdlr-core-icon-list-content"
                                        style={{
                                          color: "#000",
                                          fontSize: "16px",
                                          fontWeight: 500,
                                          textTransform: "uppercase",
                                          letterSpacing: "9px",
                                        }}
                                      >
                                        /Kitchen Series
                                      </span>
                                    </div>
                                  </li>
                                  <li
                                    className="gdlr-core-skin-divider clearfix"
                                    style={{ marginBottom: "28px" }}
                                  >
                                    <div className="gdlr-core-icon-list-content-wrap">
                                      <span
                                        className="gdlr-core-icon-list-content"
                                        style={{
                                          color: "#000",
                                          fontSize: "16px",
                                          fontWeight: 500,
                                          textTransform: "uppercase",
                                          letterSpacing: "9px",
                                        }}
                                      >
                                        /Pooja Room Series
                                      </span>
                                    </div>
                                  </li>
                                  <li
                                    className="gdlr-core-skin-divider clearfix"
                                    style={{ marginBottom: "28px" }}
                                  >
                                    <div className="gdlr-core-icon-list-content-wrap">
                                      <span
                                        className="gdlr-core-icon-list-content"
                                        style={{
                                          color: "#000",
                                          fontSize: "16px",
                                          fontWeight: 500,
                                          textTransform: "uppercase",
                                          letterSpacing: "9px",
                                        }}
                                      >
                                        /Elevation Series
                                      </span>
                                    </div>
                                  </li>
                                  <li
                                    className="gdlr-core-skin-divider clearfix"
                                    style={{ marginBottom: "28px" }}
                                  >
                                    <div className="gdlr-core-icon-list-content-wrap">
                                      <span
                                        className="gdlr-core-icon-list-content"
                                        style={{
                                          color: "#000",
                                          fontSize: "16px",
                                          fontWeight: 500,
                                          textTransform: "uppercase",
                                          letterSpacing: "9px",
                                        }}
                                      >
                                        /Corridor Sit Out Series
                                      </span>
                                    </div>
                                  </li>
                                  <li
                                    className="gdlr-core-skin-divider clearfix"
                                    style={{ marginBottom: "28px" }}
                                  >
                                    <div className="gdlr-core-icon-list-content-wrap">
                                      <span
                                        className="gdlr-core-icon-list-content"
                                        style={{
                                          color: "#000",
                                          fontSize: "16px",
                                          fontWeight: 500,
                                          textTransform: "uppercase",
                                          letterSpacing: "9px",
                                        }}
                                      >
                                        /Plain Colours
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                <a
                                  className="gdlr-core-button gdlr-core-button-transparent gdlr-core-left-align gdlr-core-button-with-border"
                                  href="/categories"
                                  id="gdlr-core-button-id-2746"
                                >
                                  <span className="gdlr-core-content">
                                    / Read More
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="gdlr-core-pbf-wrapper"
                  style={{ padding: "100px 0px 140px 0px" }}
                  data-skin="counter HP1"
                  id="gdlr-core-wrapper-5"
                >
                  <div
                    className="gdlr-core-pbf-background-wrap"
                    style={{ backgroundColor: "#161616" }}
                  >
                    <div
                      className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                      style={{
                        backgroundImage: `url(upload/hp1-counter-bg.png)`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "top center",
                      }}
                      data-parallax-speed="0"
                    ></div>
                  </div>
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                      <div className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first">
                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js">
                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                style={{ paddingBottom: "20px" }}
                              >
                                <div className="gdlr-core-title-item-title-wrap">
                                  <h3
                                    className="gdlr-core-title-item-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "60px",
                                      fontWeight: 500,
                                      letterSpacing: "4px",
                                      textTransform: "none",
                                      color: "#000",
                                    }}
                                  >
                                    Articles.
                                    <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                <a
                                  className="gdlr-core-button gdlr-core-button-transparent gdlr-core-left-align gdlr-core-button-with-border"
                                  href="#/"
                                  id="gdlr-core-button-id-86518"
                                >
                                  <span className="gdlr-core-content">
                                    / Read The Blog
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-40"
                        id="gdlr-core-column-78372"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          style={{ padding: "150px 0px 0px 0px" }}
                        >
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-blog-item gdlr-core-item-pdb clearfix gdlr-core-style-blog-list"
                                style={{ paddingBottom: "20px" }}
                              >
                                <div
                                  className="gdlr-core-blog-item-holder gdlr-core-js-2 clearfix"
                                  data-layout="fitrows"
                                >
                                  <div className="gdlr-core-item-list gdlr-core-blog-list gdlr-core-item-pdlr">
                                    <h3
                                      className="gdlr-core-blog-title gdlr-core-skin-title"
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: 500,
                                        letterSpacing: "5px",
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      <a href="singleblog.html">
                                        Business With Remote Working
                                      </a>
                                    </h3>
                                    <div className="gdlr-core-blog-info-wrapper gdlr-core-skin-divider">
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                                        <span className="gdlr-core-blog-info-sep">
                                          /
                                        </span>
                                        <a href="singleblog.html">
                                          15 Oct 2020
                                        </a>
                                      </span>
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-author">
                                        <span className="gdlr-core-blog-info-sep">
                                          /
                                        </span>
                                        <a
                                          href="singleblog.html"
                                          title="Posts by John Smith"
                                          rel="author"
                                        >
                                          John Smith
                                        </a>
                                      </span>
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-category">
                                        <span className="gdlr-core-blog-info-sep">
                                          /
                                        </span>
                                        <a href="singleblog.html" rel="tag">
                                          Blog
                                        </a>
                                        <span className="gdlr-core-sep">,</span>
                                        <a href="singleblog.html" rel="tag">
                                          Projects
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="gdlr-core-item-list gdlr-core-blog-list gdlr-core-item-pdlr">
                                    <h3
                                      className="gdlr-core-blog-title gdlr-core-skin-title"
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: 500,
                                        letterSpacing: "5px",
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      <a href="singleblog.html">
                                        3 Reasons To Focus On Community-Building
                                      </a>
                                    </h3>
                                    <div className="gdlr-core-blog-info-wrapper gdlr-core-skin-divider">
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                                        <span className="gdlr-core-blog-info-sep">
                                          /
                                        </span>
                                        <a href="singleblog.html">
                                          15 Oct 2020
                                        </a>
                                      </span>
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-author">
                                        <span className="gdlr-core-blog-info-sep">
                                          /
                                        </span>
                                        <a
                                          href="singleblog.html"
                                          title="Posts by John Smith"
                                          rel="author"
                                        >
                                          John Smith
                                        </a>
                                      </span>
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-category">
                                        <span className="gdlr-core-blog-info-sep">
                                          /
                                        </span>
                                        <a href="singleblog.html" rel="tag">
                                          Blog
                                        </a>
                                        <span className="gdlr-core-sep">,</span>
                                        <a href="singleblog.html" rel="tag">
                                          Technology
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="gdlr-core-item-list gdlr-core-blog-list gdlr-core-item-pdlr">
                                    <h3
                                      className="gdlr-core-blog-title gdlr-core-skin-title"
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: 500,
                                        letterSpacing: "5px",
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      <a href="singleblog.html">
                                        Boosting Your Marketing Team
                                      </a>
                                    </h3>
                                    <div className="gdlr-core-blog-info-wrapper gdlr-core-skin-divider">
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                                        <span className="gdlr-core-blog-info-sep">
                                          /
                                        </span>
                                        <a href="singleblog.html">
                                          15 Oct 2020
                                        </a>
                                      </span>
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-author">
                                        <span className="gdlr-core-blog-info-sep">
                                          /
                                        </span>
                                        <a
                                          href="singleblog.html"
                                          title="Posts by John Smith"
                                          rel="author"
                                        >
                                          John Smith
                                        </a>
                                      </span>
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-category">
                                        <span className="gdlr-core-blog-info-sep">
                                          /
                                        </span>
                                        <a href="singleblog.html" rel="tag">
                                          Blog
                                        </a>
                                        <span className="gdlr-core-sep">,</span>
                                        <a href="singleblog.html" rel="tag">
                                          Insights
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align"
                                style={{ marginBottom: "0px" }}
                              >
                                <div
                                  className="gdlr-core-divider-container"
                                  style={{ maxWidth: "360px" }}
                                >
                                  <div
                                    className="gdlr-core-divider-line gdlr-core-skin-divider"
                                    style={{
                                      borderColor: "#000",
                                      borderWidth: "7px",
                                      borderRadius: "0px",
                                      MozBorderRadius: "0px",
                                      WebkitBorderRadius: "0px",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <commons.Footer />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
