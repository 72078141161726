import React, { Fragment } from "react";
import commons from "./common/commons";

const SingleBlog = () => {
  return (
    <Fragment>
      <div
        className="page-template-default page page-id-13775 theme-arki gdlr-core-body woocommerce-no-js arki-body arki-body-front arki-full arki-with-sticky-navigation arki-blockquote-style-3 gdlr-core-link-to-lightbox"
        data-home-url="/"
      >
        <commons.MobileHeader name="Blog" />
        <div className="arki-body-outer-wrapper mm-page mm-slideout">
          <div className="arki-body-wrapper clearfix arki-with-transparent-header arki-with-frame">
            <commons.Header name="blog" />
            <commons.PageHeader name="Single Blog" tag="lorem ipsum" />
            <div className="arki-page-wrapper" id="arki-page-wrapper">
              <div className="arki-blog-title-wrap arki-style-custom"></div>
              <div className="arki-content-container arki-container gdlr-core-sticky-sidebar gdlr-core-js">
                <div className="arki-sidebar-wrap clearfix arki-line-height-0 arki-sidebar-style-right">
                  <div className="arki-sidebar-center arki-column-40 arki-line-height">
                    <div className="arki-content-wrap arki-item-pdlr clearfix">
                      <div className="arki-content-area">
                        <article
                          id="post-11630"
                          className="post-11630 post type-post status-publish format-standard has-post-thumbnail hentry category-blog category-technology tag-interior tag-marketing"
                        >
                          <div className="arki-single-article clearfix">
                            <div className="arki-single-article-thumbnail arki-media-image">
                              <img
                                src="upload/shutterstock_649553899.jpg"
                                alt=""
                                width="2200"
                                height="1467"
                                title="shutterstock_649553899"
                              />
                            </div>
                            <div className="arki-single-article-content">
                              <p>
                                <strong>
                                  Far far away, behind the word mountains
                                </strong>
                                , far from the countries Vokalia and
                                Consonantia, there live the blind texts.
                                Separated they live in Bookmarksgrove right at
                                the coast of the Semantics, a large language
                                ocean. A small river named Duden flows by their
                                place and supplies it with the necessary
                                regelialia. It is a paradisematic country, in
                                which roasted.
                              </p>

                              <blockquote className="wp-block-quote">
                                <p>
                                  <em>
                                    <em>
                                      Far far away, behind the word mountains,
                                      far from the countries Vokalia and
                                      Consonantia, there live the blind texts.
                                      Separated they live in Bookmarksgrove
                                      right at the coast of the Semantics, a
                                      large language ocean. A small rive.
                                    </em>
                                  </em>
                                </p>
                              </blockquote>

                              <div className="wp-block-image">
                                <figure className="alignleft size-large is-resized">
                                  <img
                                    loading="lazy"
                                    src="upload/shutterstock_680854861-1024x683.jpg"
                                    alt=""
                                    className="wp-image-14081"
                                    width="422"
                                    height="280"
                                  />
                                </figure>
                              </div>

                              <p>
                                Far far away, behind the word mountains, far
                                from the countries Vokalia and Consonantia, t
                                <span style={{ textDecoration: "underline" }}>
                                  here live the blind texts. Separated they live
                                  in Bookm
                                </span>
                                arksgrove right at the coast of the Semantics, a
                                large language ocean. A small river named Duden
                                flows by their place and supplies it with the
                                necessary regelialia. It is a paradisematic
                                country, in which roasted. It with the necessary
                                regelialia. It is a paradisematic country, in
                                which roasted parts of sentences fly into your
                                mouth. Even the all-powerful Pointing has no
                                control about the&nbsp; initial into the belt.
                                The Big Oxmox advised her not to do so, because
                                there were thousands of bad Commas, wild
                                Question Marks and devious Semikoli, but the
                                Little Blind Text didn’t listen. She packed her
                                seven versalia, put her initial into the belt
                                and made herself on the way.
                              </p>

                              <span
                                className="gdlr-core-space-shortcode"
                                style={{ marginTop: "40px" }}
                              ></span>

                              <h4>
                                <strong>Some Bullet Points</strong>
                              </h4>

                              <div
                                style={{
                                  width: "60px",
                                  height: "1px",
                                  backgroundColor: "#111111",
                                }}
                              ></div>

                              <span
                                className="gdlr-core-space-shortcode"
                                style={{ marginTop: "30px" }}
                              ></span>

                              <ul className="list-no-left">
                                <li>Sales/Marketing Strategy</li>
                                <li>Share knowledge, ideas and skills</li>
                                <li>Search Marketing: SEO &amp; PPC</li>
                              </ul>

                              <span
                                className="gdlr-core-space-shortcode"
                                style={{ marginTop: "30px" }}
                              ></span>

                              <p>
                                Far far away, behind the word mountains, far
                                from the countries Vokalia and Consonantia,
                                there live the blind texts. Separated they live
                                in Bookmarksgrove right at the coast of the
                                Semantics, a large language ocean. A small river
                                named Duden flows by their place and supplies it
                                with the necessary regelialia. It is a
                                paradisematic country, in which roasted.
                              </p>
                            </div>
                          </div>
                        </article>
                      </div>
                      <div className="arki-page-builder-wrap arki-item-rvpdlr"></div>
                      <div className="arki-single-nav-area clearfix">
                        <span className="arki-single-nav arki-single-nav-left">
                          <span className="arki-text">Previous Post</span>
                          <a href="sinigleblog.html" rel="prev">
                            <span className="arki-single-nav-title arki-title-font">
                              Boosting Your Marketing Team
                            </span>
                          </a>
                        </span>
                        <span className="arki-single-nav arki-single-nav-right">
                          <span className="arki-text">Next Post</span>
                          <a href="sinigleblog.html" rel="next">
                            <span className="arki-single-nav-title arki-title-font">
                              Business With Remote Working
                            </span>
                          </a>
                        </span>
                      </div>
                      <div className="arki-single-related-post-wrap arki-item-rvpdlr">
                        <h3 className="arki-single-related-post-title arki-item-mglr">
                          Related Posts
                        </h3>
                        <div className="gdlr-core-blog-item-holder clearfix">
                          <div className="gdlr-core-item-list gdlr-core-item-pdlr gdlr-core-column-30 gdlr-core-column-first">
                            <div className="gdlr-core-blog-grid">
                              <div className="gdlr-core-blog-thumbnail gdlr-core-media-image gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover">
                                <a href="singleblog.html">
                                  <img
                                    src="upload/shutterstock_1610966002-700x450.jpg"
                                    alt=""
                                    width="700"
                                    height="450"
                                    title="shutterstock_1610966002"
                                  />
                                </a>
                              </div>
                              <div className="gdlr-core-blog-grid-content-wrap">
                                <div className="gdlr-core-blog-grid-date">
                                  <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                                    <span className="gdlr-core-head">
                                      <i className="icon-clock"></i>
                                    </span>
                                    <a href="singleblog.html">15 Oct 2020</a>
                                  </span>
                                </div>
                                <h3
                                  className="gdlr-core-blog-title gdlr-core-skin-title"
                                  style={{ fontSize: "20px" }}
                                >
                                  <a href="singleblog.html">
                                    Google Cloud not to pry into customer data
                                  </a>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="arki-sidebar-right arki-column-20 arki-line-height arki-line-height">
                    <div className="arki-sidebar-area arki-item-pdlr">
                      <div
                        id="gdlr-core-recent-post-widget-1"
                        className="widget widget_gdlr-core-recent-post-widget arki-widget"
                      >
                        <h1 className="arki-widget-title">Recent Articles</h1>
                        <span className="clear"></span>
                        <div className="gdlr-core-recent-post-widget-wrap gdlr-core-style-4">
                          <div className="gdlr-core-recent-post-widget clearfix">
                            <div className="gdlr-core-recent-post-widget-thumbnail gdlr-core-media-image">
                              <a href="sinigleblog.html">
                                <img
                                  src="upload/shutterstock_574926388-150x150.jpg"
                                  alt=""
                                  width="150"
                                  height="150"
                                  title="shutterstock_574926388"
                                />
                              </a>
                            </div>
                            <div className="gdlr-core-recent-post-widget-content">
                              <div className="gdlr-core-recent-post-widget-info">
                                <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                                  <span className="gdlr-core-head">
                                    <i className="icon-clock"></i>
                                  </span>
                                  <a href="singleblog.html">15 Oct 2020</a>
                                </span>
                              </div>
                              <div className="gdlr-core-recent-post-widget-title gdlr-core-title-font">
                                <a href="sinigleblog.html">
                                  Business With Remote Working
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="gdlr-core-recent-post-widget clearfix">
                            <div className="gdlr-core-recent-post-widget-thumbnail gdlr-core-media-image">
                              <a href="sinigleblog.html">
                                <img
                                  src="upload/shutterstock_602141993-150x150.jpg"
                                  alt=""
                                  width="150"
                                  height="150"
                                  title="shutterstock_602141993"
                                />
                              </a>
                            </div>
                            <div className="gdlr-core-recent-post-widget-content">
                              <div className="gdlr-core-recent-post-widget-info">
                                <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                                  <span className="gdlr-core-head">
                                    <i className="icon-clock"></i>
                                  </span>
                                  <a href="singleblog.html">15 Oct 2020</a>
                                </span>
                              </div>
                              <div className="gdlr-core-recent-post-widget-title gdlr-core-title-font">
                                <a href="sinigleblog.html">
                                  Boosting Your Marketing Team
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="gdlr-core-recent-post-widget clearfix">
                            <div className="gdlr-core-recent-post-widget-thumbnail gdlr-core-media-image">
                              <a href="singleblog.html">
                                <img
                                  src="upload/shutterstock_231095752-150x150.jpg"
                                  alt=""
                                  width="150"
                                  height="150"
                                  title="shutterstock_231095752"
                                />
                              </a>
                            </div>
                            <div className="gdlr-core-recent-post-widget-content">
                              <div className="gdlr-core-recent-post-widget-info">
                                <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                                  <span className="gdlr-core-head">
                                    <i className="icon-clock"></i>
                                  </span>
                                  <a href="singleblog.html">15 Oct 2020</a>
                                </span>
                              </div>
                              <div className="gdlr-core-recent-post-widget-title gdlr-core-title-font">
                                <a href="singleblog.html">
                                  How To Improve Your Working From Home
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <commons.Footer />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SingleBlog;
