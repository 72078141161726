import React, { Fragment } from "react";
import commons from "./common/commons";

const Contact = () => {
  return (
    <Fragment>
      <div
        className="page-template-default page page-id-13775 theme-arki gdlr-core-body woocommerce-no-js arki-body arki-body-front arki-full arki-with-sticky-navigation arki-blockquote-style-3 gdlr-core-link-to-lightbox"
        data-home-url="index.html"
      >
        <commons.MobileHeader name="Contact" />
        <div className="arki-body-outer-wrapper mm-page mm-slideout">
          <div className="arki-body-wrapper clearfix arki-with-transparent-header arki-with-frame">
            <commons.Header name="contact" />
            {/* <commons.PageHeader name="Contact" tag="Reach Us" /> */}
            <div className="arki-page-wrapper" id="arki-page-wrapper">
              <div className="gdlr-core-page-builder-body">
                <div
                  className="gdlr-core-pbf-wrapper"
                  style={{ padding: "180px 0px 160px 0px" }}
                  data-skin="white"
                  id="gdlr-core-wrapper-1"
                >
                  <div
                    className="gdlr-core-pbf-background-wrap"
                    style={{ backgroundColor: "#ffffff" }}
                  >
                    <div
                      className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                      style={{
                        opacity: 0.4,
                        backgroundImage: `url(upload/pexels-photo-911738.jpg)`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                      data-parallax-speed="0.3"
                    ></div>
                  </div>
                  <div
                    className="gdlr-core-pbf-wrapper-content gdlr-core-js"
                    data-gdlr-animation="fadeInUp"
                    data-gdlr-animation-duration="600ms"
                    data-gdlr-animation-offset="0.8"
                  >
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                      <div className="gdlr-core-pbf-element">
                        <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr">
                          <div className="gdlr-core-title-item-title-wrap">
                            <h3
                              className="gdlr-core-title-item-title gdlr-core-skin-title"
                              style={{
                                fontSize: "75px",
                                fontWeight: 600,
                                letterSpacing: "0px",
                                textTransform: "none",
                                color: "#0f0f0f",
                              }}
                            >
                              Contact Us
                              <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                            </h3>
                          </div>
                          <span
                            className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                            style={{
                              fontSize: "25px",
                              fontStyle: "normal",
                              letterSpacing: "0px",
                              color: "#545454",
                              marginTop: "15px",
                            }}
                          >
                            Get Intouch
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="gdlr-core-pbf-wrapper"
                  style={{ padding: "30px 0px 20px 0px" }}
                  data-skin="Contact link"
                >
                  <div
                    className="gdlr-core-pbf-background-wrap"
                    style={{ backgroundColor: "white" }}
                  >
                    <div
                      className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                      style={{
                        opacity: 0.14,
                        backgroundImage: `url(upload/shutterstock_356735735.png)`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                      data-parallax-speed="0.2"
                    ></div>
                  </div>
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first"
                        id="gdlr-core-column-3028"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          style={{ padding: "50px 20px 0px 20px" }}
                        >
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div
                            className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                            data-gdlr-animation="fadeInUp"
                            data-gdlr-animation-duration="600ms"
                            data-gdlr-animation-offset="0.8"
                          >
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-icon-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                                style={{ paddingBottom: "40px" }}
                              >
                                <div
                                  className="gdlr-core-icon-item-wrap gdlr-core-skin-e-background gdlr-core-icon-item-type-round"
                                  style={{
                                    backgroundColor: "#000",
                                    borderRadius: "40px",
                                    "-moz-border-radius": "40px",
                                    "-webkit-border-radius": "40px",
                                    padding: "20px 20px 20px 20px",
                                  }}
                                >
                                  <i
                                    className="gdlr-core-icon-item-icon fa fa-phone gdlr-core-skin-e-content"
                                    style={{
                                      color: "#fff",
                                      fontSize: "40px",
                                      minWidth: "40px",
                                      minHeight: "40px",
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                style={{ paddingBottom: "25px" }}
                              >
                                <div className="gdlr-core-title-item-title-wrap">
                                  <h3
                                    className="gdlr-core-title-item-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "26px",
                                      letterSpacing: "0px",
                                      textTransform: "none",
                                    }}
                                  >
                                    Phone
                                    <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align">
                                <div
                                  className="gdlr-core-divider-container"
                                  style={{ maxWidth: "30px" }}
                                >
                                  <div
                                    className="gdlr-core-divider-line gdlr-core-skin-divider"
                                    style={{
                                      borderColor: "#000",
                                      borderWidth: "3px",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                                style={{ paddingBottom: "0px" }}
                              >
                                <div
                                  className="gdlr-core-text-box-item-content"
                                  style={{
                                    textTransform: "none",
                                    color: "#000",
                                  }}
                                >
                                  <p>{""}</p>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                                <div
                                  className="gdlr-core-text-box-item-content"
                                  style={{
                                    fontWeight: 700,
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    <a
                                      style={{ textDecoration: "underline" }}
                                      href="tel:7780258182"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      +91 7780258182
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-20"
                        id="gdlr-core-column-44548"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          style={{ padding: "50px 20px 0px 20px" }}
                        >
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div
                            className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                            data-gdlr-animation="fadeInDown"
                            data-gdlr-animation-duration="600ms"
                            data-gdlr-animation-offset="0.8"
                          >
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-icon-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                                style={{ paddingBottom: "40px" }}
                              >
                                <div
                                  className="gdlr-core-icon-item-wrap gdlr-core-skin-e-background gdlr-core-icon-item-type-round"
                                  style={{
                                    backgroundColor: "#000",
                                    borderRadius: "40px",
                                    "-moz-border-radius": "40px",
                                    "-webkit-border-radius": "40px",
                                    padding: "24px 24px 24px 24px",
                                  }}
                                >
                                  <i
                                    className="gdlr-core-icon-item-icon fa fa-envelope-o gdlr-core-skin-e-content"
                                    style={{
                                      color: "#fff",
                                      fontSize: "31px",
                                      minWidth: "31px",
                                      minHeight: "31px",
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                style={{ paddingBottom: "25px" }}
                              >
                                <div className="gdlr-core-title-item-title-wrap">
                                  <h3
                                    className="gdlr-core-title-item-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "26px",
                                      letterSpacing: "0px",
                                      textTransform: "none",
                                    }}
                                  >
                                    Email
                                    <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align">
                                <div
                                  className="gdlr-core-divider-container"
                                  style={{ maxWidth: "30px" }}
                                >
                                  <div
                                    className="gdlr-core-divider-line gdlr-core-skin-divider"
                                    style={{
                                      borderColor: "#000",
                                      borderWidth: "3px",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                                style={{ paddingBottom: "0px" }}
                              >
                                <div
                                  className="gdlr-core-text-box-item-content"
                                  style={{
                                    textTransform: "none",
                                    color: "#000",
                                  }}
                                >
                                  <p>{""}</p>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                                <div
                                  className="gdlr-core-text-box-item-content"
                                  style={{
                                    fontWeight: 700,
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    <a
                                      style={{ textDecoration: "underline" }}
                                      href="mailto:mail@vevon.in"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="__cf_email__">
                                        mail@vevon.in
                                      </span>
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-20"
                        id="gdlr-core-column-23743"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          style={{ padding: "50px 20px 0px 20px" }}
                        >
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div
                            className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                            data-gdlr-animation="fadeInUp"
                            data-gdlr-animation-duration="600ms"
                            data-gdlr-animation-offset="0.8"
                          >
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-icon-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                                style={{ paddingBottom: "40px" }}
                              >
                                <div
                                  className="gdlr-core-icon-item-wrap gdlr-core-skin-e-background gdlr-core-icon-item-type-round"
                                  style={{
                                    backgroundColor: "#000",
                                    borderRadius: "40px",
                                    "-moz-border-radius": "40px",
                                    "-webkit-border-radius": "40px",
                                    padding: "24px 24px 24px 24px",
                                  }}
                                >
                                  <i
                                    className="gdlr-core-icon-item-icon fa fa-location-arrow gdlr-core-skin-e-content"
                                    style={{
                                      color: "#ffffff",
                                      fontSize: "31px",
                                      minWidth: "31px",
                                      minHeight: "31px",
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                style={{ paddingBottom: "25px" }}
                              >
                                <div className="gdlr-core-title-item-title-wrap">
                                  <h3
                                    className="gdlr-core-title-item-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "26px",
                                      letterSpacing: "0px",
                                      textTransform: "none",
                                    }}
                                  >
                                    Location
                                    <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-center-align">
                                <div
                                  className="gdlr-core-divider-container"
                                  style={{ maxWidth: "30px" }}
                                >
                                  <div
                                    className="gdlr-core-divider-line gdlr-core-skin-divider"
                                    style={{
                                      borderColor: "#000",
                                      borderWidth: "3px",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                                style={{ paddingBottom: "0px" }}
                              >
                                <div
                                  className="gdlr-core-text-box-item-content"
                                  style={{
                                    textTransform: "none",
                                    color: "#000",
                                  }}
                                >
                                  <p>
                                    G4, AB Plaza,
                                    <br />
                                    Poornachandra Nagar Road, Vijayawada, <br />
                                    Andhra Pradesh, 520013
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                                <div
                                  className="gdlr-core-text-box-item-content"
                                  style={{
                                    fontWeight: 700,
                                    textTransform: "none",
                                  }}
                                >
                                  <p>
                                    <a
                                      style={{ textDecoration: "underline" }}
                                      href="https://www.google.com/maps/place/MODULO+CERAMICS/@16.4937818,80.6453092,15z/data=!4m5!3m4!1s0x0:0x9c56f2c9947b2e27!8m2!3d16.4937811!4d80.6453107"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View On Google Map
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="gdlr-core-pbf-wrapper"
                  style={{ padding: "90px 0px 35px 0px" }}
                >
                  <div
                    className="gdlr-core-pbf-background-wrap"
                    style={{ backgroundColor: "#ffffff" }}
                  ></div>
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
                        data-skin="Contact Form"
                        id="gdlr-core-column-12647"
                      >
                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js">
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div
                            className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                            style={{ maxWidth: "760px" }}
                          >
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                                style={{ paddingBottom: "60px" }}
                              >
                                <div className="gdlr-core-title-item-title-wrap">
                                  <h3
                                    className="gdlr-core-title-item-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "39px",
                                      fontWeight: 600,
                                      letterSpacing: "0px",
                                      textTransform: "none",
                                    }}
                                  >
                                    Leave us your info
                                    <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                  </h3>
                                </div>
                                <span
                                  className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                  style={{
                                    fontSize: "19px",
                                    fontStyle: "normal",
                                    letterSpacing: "0px",
                                  }}
                                >
                                  and we will get back to you.
                                </span>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-contact-form-7-item gdlr-core-item-pdlr gdlr-core-item-pdb">
                                <div
                                  role="form"
                                  className="wpcf7"
                                  id="wpcf7-f1979-p1964-o1"
                                  lang="en-US"
                                  dir="ltr"
                                >
                                  <div className="screen-reader-response">
                                    <p
                                      role="status"
                                      aria-live="polite"
                                      aria-atomic="true"
                                    ></p>
                                    <ul></ul>
                                  </div>

                                  <form
                                    method="post"
                                    action="https://max-themes.net/demos/arki/arki/minimal/contactform/contactengine.php"
                                  >
                                    <input
                                      type="text"
                                      name="Name"
                                      id="Name"
                                      placeholder="Name:"
                                      className="input"
                                    />

                                    <input
                                      type="text"
                                      name="City"
                                      id="City"
                                      placeholder="City:"
                                      className="input"
                                    />

                                    <input
                                      type="text"
                                      name="Email"
                                      id="Email"
                                      placeholder="Email:"
                                      className="input"
                                    />

                                    <textarea
                                      name="Message"
                                      rows="20"
                                      cols="20"
                                      id="Message"
                                      placeholder="Message:"
                                      className="input"
                                    ></textarea>

                                    <input
                                      type="submit"
                                      name="submit"
                                      value="Submit"
                                      className="submit-button"
                                    />
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="gdlr-core-pbf-wrapper"
                  style={{ padding: "0px 0px 0px 0px" }}
                >
                  <div className="gdlr-core-pbf-background-wrap"></div>
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full-no-space">
                      <div className="gdlr-core-pbf-element">
                        <div
                          className="gdlr-core-wp-google-map-plugin-item gdlr-core-item-pdlr gdlr-core-item-pdb"
                          style={{ paddingBottom: "0px" }}
                        >
                          <div
                            className="wpgmp_map_container wpgmp-map-1"
                            rel="map1"
                          >
                            {/* <iframe
                              title="location"
                              style={{
                                width: "100%",
                                height: "500px",
                                border: "0",
                              }}
                              src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d19905.696399556287!2d-0.3007084089960577!3d51.417531259591925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s2334+Peterson+Street+Kingston+UK+London+H14D!5e0!3m2!1sen!2s!4v1546697086219"
                              width="600"
                              height="450"
                            ></iframe> */}
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15302.558009037803!2d80.6453092!3d16.4937818!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9c56f2c9947b2e27!2sMODULO%20CERAMICS!5e0!3m2!1sen!2sin!4v1651406502648!5m2!1sen!2sin"
                              style={{
                                width: "100%",
                                height: "500px",
                                border: "0",
                              }}
                              allowFullScreen
                              loading="lazy"
                              referrerpolicy="no-referrer-when-downgrade"
                              title="vevonceramicsmap"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="gdlr-core-pbf-wrapper"
                  style={{ padding: "80px 0px 50px 0px" }}
                >
                  <div
                    className="gdlr-core-pbf-background-wrap"
                    style={{ backgroundColor: "#ffffff" }}
                  ></div>
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                      <div className="gdlr-core-pbf-element">
                        <div className="gdlr-core-social-network-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-item-pdlr gdlr-direction-horizontal">
                          <a
                            href="https://instagram.com/_.vevonceramics._?utm_medium=copy_link"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="gdlr-core-social-network-icon"
                            title="instagram"
                            style={{
                              fontSize: "20px",
                              color: "#1e1e1e",
                              marginRight: "40px",
                            }}
                          >
                            <i className="fa fa-instagram"></i>
                          </a>
                          <a
                            href="https://www.facebook.com/Vevon-Ceramics-108284508429161"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="gdlr-core-social-network-icon"
                            title="facebook"
                            style={{
                              fontSize: "20px",
                              color: "#1e1e1e",
                              marginRight: "40px",
                            }}
                          >
                            <i className="fa fa-facebook"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <commons.Footer />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Contact;
