import About from "./About";
import Home from "./Home";
import Categories from "./Categories";
import Contact from "./Contact";
import Blog from "./Blog";
import Category from "./Category";
import Article from "./Article";
import SingleBlog from "./SingleBlog";
import TrailingSlash from "./TrailingSlash";
import Gallery from "./Gallery";
import Catalogue from "./Catalogue";

const compos = {
  Home,
  About,
  Gallery,
  Categories,
  Contact,
  Blog,
  Category,
  Article,
  Catalogue,
  SingleBlog,
  TrailingSlash,
};

export default compos;
