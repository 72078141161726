import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import compos from "./components/compos";

const App = () => {
  return (
    <Router>
      {/* <compos.TrailingSlash /> */}
      <Routes>
        <Route path="/" element={<compos.Home />} />
        <Route path="about" element={<compos.About />} />
        <Route path="categories" element={<compos.Categories />} />
        <Route path="catalogue" element={<compos.Catalogue />} />
        <Route path="gallery" element={<compos.Gallery />} />
        <Route path="category" element={<compos.Category />} />
        <Route path="article" element={<compos.Article />} />
        <Route path="contact" element={<compos.Contact />} />
        <Route path="blog" element={<compos.Blog />} />
        <Route path="singleblog" element={<compos.SingleBlog />} />
      </Routes>
    </Router>
  );
};

export default App;
