import React, { Fragment, useEffect, useRef } from "react";
import commons from "./common/commons";

const Category = () => {
  const sref = useRef(null);
  const location = commons.useLocation();

  const scrollToRef = (ref) => ref.current.scrollIntoView();

  useEffect(() => {
    scrollToRef(sref);
    if (location && location.state && Object.keys(location.state).length > 0) {
      // window.location = "/category";
    } else {
      window.location = "/";
    }
  }, [location]);

  return (
    <Fragment>
      <div
        className="page-template-default page page-id-13775 theme-arki gdlr-core-body woocommerce-no-js arki-body arki-body-front arki-full arki-with-sticky-navigation arki-blockquote-style-3 gdlr-core-link-to-lightbox"
        data-home-url="/"
      >
        <commons.MobileHeader name="Categories" />
        <div className="arki-body-outer-wrapper mm-page mm-slideout">
          <div className="arki-body-wrapper clearfix arki-with-transparent-header arki-with-frame">
            <commons.Header name="categories" />
            <commons.PageHeader
              name={location && location.state && location.state.category}
              tag="Tiles Of Different Categories"
            />
            <div
              className="arki-page-wrapper"
              id="arki-page-wrapper"
              ref={sref}
            >
              <div className="gdlr-core-page-builder-body">
                <div
                  className="gdlr-core-pbf-sidebar-wrapper"
                  id="gdlr-core-sidebar-wrapper-1"
                >
                  <div className="gdlr-core-pbf-sidebar-container gdlr-core-line-height-0 clearfix gdlr-core-js gdlr-core-container services-page">
                    <div
                      className="gdlr-core-pbf-sidebar-content gdlr-core-column-45 gdlr-core-pbf-sidebar-padding gdlr-core-line-height gdlr-core-column-extend-right"
                      style={{ padding: "90px 0px 100px 0px" }}
                    >
                      <div className="gdlr-core-pbf-background-wrap">
                        <div
                          className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                          style={{
                            backgroundImage: `url(upload/BG-SVC.jpg)`,
                            backgroundRepeat: "repeat-y",
                            backgroundPosition: "center",
                          }}
                          data-parallax-speed="0"
                        ></div>
                      </div>
                      <div className="gdlr-core-pbf-sidebar-content-inner">
                        <div
                          className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
                          id="gdlr-core-column-14032"
                        >
                          <div
                            className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                            style={{ paddingTop: "40px" }}
                          >
                            <div className="gdlr-core-pbf-background-wrap"></div>
                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                              <div className="gdlr-core-pbf-element">
                                <div
                                  className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                  style={{ paddingBottom: "0px" }}
                                >
                                  <div
                                    className="gdlr-core-text-box-item-content"
                                    style={{
                                      fontSize: "17px",
                                      fontWeight: 400,
                                      letterSpacing: "1px",
                                      textTransform: "none",
                                      color: "#676767",
                                    }}
                                  >
                                    {location &&
                                    location.state &&
                                    location.state.category &&
                                    location.state.category ===
                                      "Glossy Tiles" ? (
                                      <div>
                                        <p>
                                          Glossy tiles have a mirror-like
                                          structure. Their reflective surfaces
                                          bounce light all around the area,
                                          making the room seem bigger and
                                          brighter. These tiles are a great
                                          choice for confined spaces as well as
                                          for the rooms that receive less
                                          natural light.
                                        </p>
                                      </div>
                                    ) : location.state.category ===
                                      "Kitchen Series" ? (
                                      <div>
                                        <p>
                                          Staining is the most common problem in
                                          every kitchen. So, to solve this
                                          problem, we supply stain & scratch
                                          free kitchen wall tiles. Kitchen is
                                          one such space in the house that has
                                          the scope of installing several
                                          different types of tiles, in different
                                          applications, all of which will
                                          enhance your kitchen beautifully.
                                        </p>
                                      </div>
                                    ) : location.state.category ===
                                      "Pooja Room Series" ? (
                                      <div>
                                        <p>
                                          Pooja Room is particularly the most
                                          sacred room in the house, and so it
                                          should be designed keeping in mind the
                                          ease of maintenance and cleaning. Our
                                          Pooja Tiles are designed in a way that
                                          gives out a feeling of an actual
                                          worship space with divine blissful
                                          patterns with the deities and regular
                                          Pooja Samagri printed all over the
                                          tiles.
                                        </p>
                                      </div>
                                    ) : location.state.category ===
                                      "Corridor Sit Out Series" ? (
                                      <div>
                                        <p>
                                          In Matt tiles, a special layer is
                                          added on the topmost layer to achieve
                                          a non-shiny and subtle look. The most
                                          prominent feature of Matte tiles is
                                          their anti-slippery nature, which
                                          makes them an ideal flooring option
                                          for all spaces, including those with
                                          high water usage like bathrooms,
                                          kitchens, and balconies.
                                        </p>
                                        <p>
                                          Outdoor areas are preferably tiled
                                          with Matt because of their slip
                                          resistance and durability, and the
                                          fact that they can fight weather
                                          extremities.
                                        </p>
                                      </div>
                                    ) : location.state.category ===
                                      "Elevation Series" ? (
                                      <div>
                                        <p>
                                          Elevation tiles display stone like
                                          colorful patterns. These tiles are in
                                          high demand since they amplify the
                                          home space and are designed with a
                                          perfect blend of natural visuals and
                                          soothing modern hues. These tiles are
                                          quite appealing and attract the most
                                          attention because of their uniqueness
                                          and innovative placement.
                                        </p>
                                      </div>
                                    ) : location.state.category === "Plain" ? (
                                      <div>
                                        <p>
                                          This category is suitable for spaces
                                          you would like to keep simple, low-key
                                          and minimal.
                                        </p>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
                          id="gdlr-core-column-40743"
                        >
                          <div
                            className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                            style={{ padding: "40px 0px 0px 0px" }}
                          >
                            <div className="gdlr-core-pbf-background-wrap"></div>
                            <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                              <div className="gdlr-core-pbf-element">
                                {location &&
                                  location.state &&
                                  location.state.category && (
                                    <div className="gdlr-core-gallery-item gdlr-core-item-pdb clearfix gdlr-core-gallery-item-style-slider gdlr-core-item-pdlr">
                                      <commons.OwlCarousel
                                        autoPlay
                                        className="gdlr-core-flexslider flexslider gdlr-core-js-2"
                                        items={1}
                                        loop
                                      >
                                        <div>
                                          <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                            <a
                                              className="gdlr-core-lightgallery gdlr-core-js"
                                              href={
                                                location.state.category ===
                                                "Glossy Tiles"
                                                  ? "upload/single-category/glossy-tiles-slide1.png"
                                                  : location.state.category ===
                                                    "Kitchen Series"
                                                  ? "upload/single-category/kitchen-series-slide1.png"
                                                  : location.state.category ===
                                                    "Pooja Room Series"
                                                  ? "upload/single-category/pooja-room-slide1.png"
                                                  : location.state.category ===
                                                    "Elevation Series"
                                                  ? "upload/single-category/elevation-series-slide1.png"
                                                  : location.state.category ===
                                                    "Corridor Sit Out Series"
                                                  ? "upload/single-category/corridor-sit-out-slide1.png"
                                                  : "upload/single-category/plain-slide1.png"
                                              }
                                              data-lightbox-group="gdlr-core-img-group-4"
                                            >
                                              <img
                                                src={
                                                  location.state.category ===
                                                  "Glossy Tiles"
                                                    ? "upload/single-category/glossy-tiles-slide1.png"
                                                    : location.state
                                                        .category ===
                                                      "Kitchen Series"
                                                    ? "upload/single-category/kitchen-series-slide1.png"
                                                    : location.state
                                                        .category ===
                                                      "Pooja Room Series"
                                                    ? "upload/single-category/pooja-room-slide1.png"
                                                    : location.state
                                                        .category ===
                                                      "Elevation Series"
                                                    ? "upload/single-category/elevation-series-slide1.png"
                                                    : location.state
                                                        .category ===
                                                      "Corridor Sit Out Series"
                                                    ? "upload/single-category/corridor-sit-out-slide1.png"
                                                    : "upload/single-category/plain-slide1.png"
                                                }
                                                key={Math.random()}
                                                alt=""
                                                width="1550"
                                                height="700"
                                                title="Image Title"
                                              />
                                              <span className="gdlr-core-image-overlay gdlr-core-gallery-image-overlay gdlr-core-left-align">
                                                <span className="gdlr-core-image-overlay-content">
                                                  <span className="gdlr-core-image-overlay-title gdlr-core-title-font">
                                                    {location.state.category}
                                                  </span>
                                                </span>
                                              </span>
                                            </a>
                                          </div>
                                        </div>
                                        {/* <div>
                                      <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                        <a
                                          className="gdlr-core-lightgallery gdlr-core-js"
                                          href="upload/slide2.png"
                                          data-lightbox-group="gdlr-core-img-group-4"
                                        >
                                          <img
                                            src="upload/slide2.png"
                                            alt=""
                                            width="1550"
                                            height="700"
                                            title="Image Title"
                                          />
                                          <span className="gdlr-core-image-overlay gdlr-core-gallery-image-overlay gdlr-core-left-align">
                                            <span className="gdlr-core-image-overlay-content">
                                              <span className="gdlr-core-image-overlay-title gdlr-core-title-font">
                                                Image Title
                                              </span>
                                            </span>
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="gdlr-core-gallery-list gdlr-core-media-image">
                                        <a
                                          className="gdlr-core-lightgallery gdlr-core-js"
                                          href="upload/slide3.png"
                                          data-lightbox-group="gdlr-core-img-group-4"
                                        >
                                          <img
                                            src="upload/slide3.png"
                                            alt=""
                                            width="1550"
                                            height="700"
                                            title="Image Title"
                                          />
                                          <span className="gdlr-core-image-overlay gdlr-core-gallery-image-overlay gdlr-core-left-align">
                                            <span className="gdlr-core-image-overlay-content">
                                              <span className="gdlr-core-image-overlay-title gdlr-core-title-font">
                                                Image Title
                                              </span>
                                            </span>
                                          </span>
                                        </a>
                                      </div>
                                    </div> */}
                                      </commons.OwlCarousel>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="gdlr-core-pbf-sidebar-left gdlr-core-column-extend-left arki-sidebar-area gdlr-core-column-15 gdlr-core-pbf-sidebar-padding gdlr-core-line-height"
                      style={{ padding: "100px 0px 30px 0px" }}
                    >
                      <div className="gdlr-core-sidebar-item gdlr-core-item-pdlr">
                        <div
                          id="gdlr-core-custom-menu-widget-2"
                          className="widget widget_gdlr-core-custom-menu-widget arki-widget"
                        >
                          <h1 className="arki-widget-title">Our Categories</h1>
                          <span className="clear"></span>
                          <div className="menu-services-container">
                            <ul
                              id="menu-services"
                              className="gdlr-core-custom-menu-widget gdlr-core-menu-style-plain"
                            >
                              <li
                                className={
                                  location &&
                                  location.state &&
                                  location.state.category === "Glossy Tiles"
                                    ? "menu-item current-menu-item arki-normal-menu"
                                    : "menu-item arki-normal-menu"
                                }
                              >
                                <commons.Link
                                  to="/category"
                                  state={{ category: "Glossy Tiles" }}
                                  onClick={() => window.location.reload()}
                                >
                                  Glossy Tiles
                                </commons.Link>
                              </li>
                              <li
                                className={
                                  location &&
                                  location.state &&
                                  location.state.category === "Kitchen Series"
                                    ? "menu-item current-menu-item arki-normal-menu"
                                    : "menu-item arki-normal-menu"
                                }
                              >
                                <commons.Link
                                  to="/category"
                                  state={{ category: "Kitchen Series" }}
                                  onClick={() => window.location.reload()}
                                >
                                  Kitchen Series
                                </commons.Link>
                              </li>
                              <li
                                className={
                                  location &&
                                  location.state &&
                                  location.state.category ===
                                    "Pooja Room Series"
                                    ? "menu-item current-menu-item arki-normal-menu"
                                    : "menu-item arki-normal-menu"
                                }
                              >
                                <commons.Link
                                  to="/category"
                                  state={{ category: "Pooja Room Series" }}
                                  onClick={() => window.location.reload()}
                                >
                                  Pooja Room Series
                                </commons.Link>
                              </li>
                              <li
                                className={
                                  location &&
                                  location.state &&
                                  location.state.category === "Elevation Series"
                                    ? "menu-item current-menu-item arki-normal-menu"
                                    : "menu-item arki-normal-menu"
                                }
                              >
                                <commons.Link
                                  to="/category"
                                  state={{ category: "Elevation Series" }}
                                  onClick={() => window.location.reload()}
                                >
                                  Elevation Series
                                </commons.Link>
                              </li>
                              <li
                                className={
                                  location &&
                                  location.state &&
                                  location.state.category ===
                                    "Corridor Sit Out Series"
                                    ? "menu-item current-menu-item arki-normal-menu"
                                    : "menu-item arki-normal-menu"
                                }
                              >
                                <commons.Link
                                  to="/category"
                                  state={{
                                    category: "Corridor Sit Out Series",
                                  }}
                                  onClick={() => window.location.reload()}
                                >
                                  Corridor Sit Out Series
                                </commons.Link>
                              </li>
                              <li
                                className={
                                  location &&
                                  location.state &&
                                  location.state.category === "Plain"
                                    ? "menu-item current-menu-item arki-normal-menu"
                                    : "menu-item arki-normal-menu"
                                }
                              >
                                <commons.Link
                                  to="/category"
                                  state={{ category: "Plain" }}
                                  onClick={() => window.location.reload()}
                                >
                                  Plain
                                </commons.Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="gdlr-core-pbf-wrapper"
                  style={{ padding: "130px 0px 130px 0px" }}
                >
                  <div className="gdlr-core-pbf-background-wrap">
                    <div
                      className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                      style={{
                        backgroundImage: `url(upload/AB05-BG-Black.jpg)`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                      data-parallax-speed="0"
                    ></div>
                  </div>
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
                        id="gdlr-core-column-16919"
                      >
                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js">
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div
                            className="gdlr-core-pbf-column-content clearfix gdlr-core-js"
                            style={{ maxWidth: "720px" }}
                          >
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-center-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                style={{ paddingBottom: "35px" }}
                              >
                                <div className="gdlr-core-title-item-title-wrap">
                                  <h3
                                    className="gdlr-core-title-item-title gdlr-core-skin-title"
                                    style={{
                                      fontSize: "43px",
                                      fontWeight: 500,
                                      letterSpacing: "4px",
                                      textTransform: "none",
                                      color: "#ffffff",
                                    }}
                                  >
                                    Check Out Our Works
                                    <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align gdlr-core-no-p-space"
                                style={{ paddingBottom: "50px" }}
                              >
                                <div
                                  className="gdlr-core-text-box-item-content"
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: 400,
                                    letterSpacing: "1px",
                                    textTransform: "none",
                                    color: "#ffffff",
                                  }}
                                >
                                  <p>
                                    We work closely with clients to gain an
                                    understanding of your desires both philoso
                                    phical and practical.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align"
                                style={{ paddingBottom: "0px" }}
                              >
                                <a
                                  className="gdlr-core-button gdlr-core-button-transparent gdlr-core-center-align gdlr-core-button-with-border"
                                  href="#/"
                                  style={{
                                    fontSize: "17px",
                                    fontWeight: 500,
                                    letterSpacing: "1px",
                                    textTransform: "none",
                                    borderRadius: "0px",
                                    "-moz-border-radius": "0px",
                                    "-webkit-border-radius": "0px",
                                    borderWidth: "2px 2px 2px 2px",
                                    borderColor: "#ffffff",
                                  }}
                                >
                                  <span className="gdlr-core-content">
                                    View Portfolio
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <commons.Footer />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Category;
