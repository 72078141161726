import React, { Fragment } from "react";

const Header = ({ name }) => {
  return (
    <Fragment>
      <div className={"arki-body-wrapper clearfix arki-with-frame"}>
        <header
          className="arki-header-wrap arki-header-style-plain arki-style-menu-right arki-sticky-navigation arki-style-fixed"
          data-navigation-offset="75"
        >
          <div className="arki-header-background"></div>
          <div className="arki-header-container arki-container">
            <div className="arki-header-container-inner clearfix">
              <div className="arki-logo arki-item-pdlr">
                <div className="arki-logo-inner">
                  <a className="arki-fixed-nav-logo" href="/">
                    <img
                      src="upload/logo.png"
                      alt=""
                      width="82"
                      height="25"
                      title="logo"
                    />
                  </a>
                  <a className="arki-orig-logo" href="/">
                    <img
                      src="upload/white-logo.png"
                      alt=""
                      width="82"
                      height="25"
                      title="logo"
                    />
                  </a>
                </div>
              </div>
              <div className="arki-navigation arki-item-pdlr clearfix">
                <div className="arki-main-menu" id="arki-main-menu">
                  <ul id="menu-main-navigation-1" className="sf-menu">
                    <li
                      className={
                        name === "home"
                          ? "menu-item menu-item-home current-menu-item current-menu-ancestor menu-item-has-children arki-normal-menu"
                          : "menu-item menu-item-home menu-item-has-children arki-normal-menu"
                      }
                    >
                      <a href="/" className="sf-with-ul-pre">
                        Home
                      </a>
                    </li>
                    <li
                      className={
                        name === "about"
                          ? "menu-item current-menu-item current-menu-ancestor menu-item-has-children arki-normal-menu"
                          : "menu-item menu-item-has-children arki-normal-menu"
                      }
                    >
                      <a href="/about" className="sf-with-ul-pre">
                        About Us
                      </a>
                    </li>
                    <li
                      className={
                        name === "categories"
                          ? "menu-item current-menu-item current-menu-ancestor menu-item-has-children arki-normal-menu"
                          : "menu-item menu-item-has-children arki-normal-menu"
                      }
                    >
                      <a href="/categories" className="sf-with-ul-pre">
                        Categories
                      </a>
                    </li>
                    <li
                      className={
                        name === "catalogue"
                          ? "menu-item current-menu-item current-menu-ancestor menu-item-has-children arki-normal-menu"
                          : "menu-item menu-item-has-children arki-normal-menu"
                      }
                    >
                      <a href="/catalogue" className="sf-with-ul-pre">
                        Catalogue
                      </a>
                    </li>
                    <li
                      className={
                        name === "contact"
                          ? "menu-item current-menu-item current-menu-ancestor menu-item-has-children arki-normal-menu"
                          : "menu-item menu-item-has-children arki-normal-menu"
                      }
                    >
                      <a href="/contact" className="sf-with-ul-pre">
                        Contact
                      </a>
                    </li>
                    <li
                      className={
                        name === "blog"
                          ? "menu-item current-menu-item current-menu-ancestor menu-item-has-children arki-normal-menu"
                          : "menu-item menu-item-has-children arki-normal-menu"
                      }
                    >
                      <a href="/blog" className="sf-with-ul-pre">
                        Blog
                      </a>
                    </li>
                  </ul>
                  <div
                    className="arki-navigation-slide-bar arki-navigation-slide-bar-style-2 arki-left"
                    data-size-offset="0"
                    data-width="19px"
                    id="arki-navigation-slide-bar"
                  ></div>
                </div>
                {/* <div className="arki-main-menu-right-wrap clearfix">
                  <div className="arki-side-content-menu-button">
                    <span></span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </header>
      </div>
    </Fragment>
  );
};

export default Header;
