import React, { Fragment } from "react";
import commons from "./common/commons";

const Blog = () => {
  return (
    <Fragment>
      <div
        className="page-template-default page page-id-13775 theme-arki gdlr-core-body woocommerce-no-js arki-body arki-body-front arki-full arki-with-sticky-navigation arki-blockquote-style-3 gdlr-core-link-to-lightbox"
        data-home-url="/"
      >
        <commons.MobileHeader name="Blog" />
        <div className="arki-body-outer-wrapper mm-page mm-slideout">
          <div className="arki-body-wrapper clearfix arki-with-transparent-header arki-with-frame">
            <commons.Header name="blog" />
            <commons.PageHeader name="Our Blog" tag="lorem ipsum" />
            <div className="arki-page-wrapper" id="arki-page-wrapper">
              <div className="gdlr-core-page-builder-body">
                <div className="gdlr-core-pbf-section">
                  <div className="gdlr-core-pbf-section-container gdlr-core-container clearfix">
                    <div className="gdlr-core-pbf-element">
                      <div
                        className="gdlr-core-blog-item gdlr-core-item-pdb clearfix gdlr-core-style-blog-image"
                        style={{ paddingBottom: "10px" }}
                      >
                        <div
                          className="gdlr-core-blog-item-holder gdlr-core-js-2 clearfix"
                          data-layout="fitrows"
                        >
                          <div className="gdlr-core-item-list gdlr-core-item-pdlr gdlr-core-item-mgb gdlr-core-column-30 gdlr-core-column-first">
                            <div className="gdlr-core-blog-modern gdlr-core-with-image gdlr-core-hover-overlay-content gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover gdlr-core-style-1 gdlr-core-outer-frame-element">
                              <a href="/singleblog">
                                <div className="gdlr-core-blog-modern-inner">
                                  <div className="gdlr-core-blog-thumbnail gdlr-core-media-image">
                                    <img
                                      src="upload/blog1.png"
                                      alt=""
                                      width="600"
                                      height="600"
                                      title="shutterstock_574926388"
                                    />
                                  </div>
                                  <div className="gdlr-core-blog-modern-content gdlr-core-center-align">
                                    <h3
                                      className="gdlr-core-blog-title gdlr-core-skin-title"
                                      style={{
                                        fontSize: "26px",
                                        fontWeight: 700,
                                        letterSpacing: "0px",
                                      }}
                                    >
                                      <a href="singleblog.html">
                                        Business With Remote Working
                                      </a>
                                    </h3>
                                    <div className="gdlr-core-blog-info-wrapper gdlr-core-skin-divider">
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                                        <span className="gdlr-core-blog-info-sep">
                                          •
                                        </span>
                                        <span className="gdlr-core-head">
                                          <i className="icon-clock"></i>
                                        </span>
                                        <a href="singleblog.html">
                                          15 Oct 2020
                                        </a>
                                      </span>
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-author">
                                        <span className="gdlr-core-blog-info-sep">
                                          •
                                        </span>
                                        <span className="gdlr-core-head">
                                          <i className="icon-user"></i>
                                        </span>
                                        <a
                                          href="singleblog.html"
                                          title="Posts by John Smith"
                                          rel="author"
                                        >
                                          John Smith
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div className="gdlr-core-item-list gdlr-core-item-pdlr gdlr-core-item-mgb gdlr-core-column-30">
                            <div className="gdlr-core-blog-modern gdlr-core-with-image gdlr-core-hover-overlay-content gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover gdlr-core-style-1 gdlr-core-outer-frame-element">
                              <a href="/singleblog">
                                <div className="gdlr-core-blog-modern-inner">
                                  <div className="gdlr-core-blog-thumbnail gdlr-core-media-image">
                                    <img
                                      src="upload/shutterstock_649553899-600x600.jpg"
                                      alt=""
                                      width="600"
                                      height="600"
                                      title="shutterstock_649553899"
                                    />
                                  </div>
                                  <div className="gdlr-core-blog-modern-content gdlr-core-center-align">
                                    <h3
                                      className="gdlr-core-blog-title gdlr-core-skin-title"
                                      style={{
                                        fontSize: "26px",
                                        fontWeight: 700,
                                        letterSpacing: "0px",
                                      }}
                                    >
                                      <a href="singleblog.html">
                                        3 Reasons To Focus On Community-Building
                                      </a>
                                    </h3>
                                    <div className="gdlr-core-blog-info-wrapper gdlr-core-skin-divider">
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                                        <span className="gdlr-core-blog-info-sep">
                                          •
                                        </span>
                                        <span className="gdlr-core-head">
                                          <i className="icon-clock"></i>
                                        </span>
                                        <a href="singleblog.html">
                                          15 Oct 2020
                                        </a>
                                      </span>
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-author">
                                        <span className="gdlr-core-blog-info-sep">
                                          •
                                        </span>
                                        <span className="gdlr-core-head">
                                          <i className="icon-user"></i>
                                        </span>
                                        <a
                                          href="singleblog.html"
                                          title="Posts by John Smith"
                                          rel="author"
                                        >
                                          John Smith
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div className="gdlr-core-item-list gdlr-core-item-pdlr gdlr-core-item-mgb gdlr-core-column-30 gdlr-core-column-first">
                            <div className="gdlr-core-blog-modern gdlr-core-with-image gdlr-core-hover-overlay-content gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover gdlr-core-style-1 gdlr-core-outer-frame-element">
                              <a href="/singleblog">
                                <div className="gdlr-core-blog-modern-inner">
                                  <div className="gdlr-core-blog-thumbnail gdlr-core-media-image">
                                    <img
                                      src="upload/shutterstock_602141993-600x600.jpg"
                                      alt=""
                                      width="600"
                                      height="600"
                                      title="shutterstock_602141993"
                                    />
                                  </div>
                                  <div className="gdlr-core-blog-modern-content gdlr-core-center-align">
                                    <h3
                                      className="gdlr-core-blog-title gdlr-core-skin-title"
                                      style={{
                                        fontSize: "26px",
                                        fontWeight: 700,
                                        letterSpacing: "0px",
                                      }}
                                    >
                                      <a href="singleblog.html">
                                        Boosting Your Marketing Team
                                      </a>
                                    </h3>
                                    <div className="gdlr-core-blog-info-wrapper gdlr-core-skin-divider">
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                                        <span className="gdlr-core-blog-info-sep">
                                          •
                                        </span>
                                        <span className="gdlr-core-head">
                                          <i className="icon-clock"></i>
                                        </span>
                                        <a href="singleblog.html">
                                          15 Oct 2020
                                        </a>
                                      </span>
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-author">
                                        <span className="gdlr-core-blog-info-sep">
                                          •
                                        </span>
                                        <span className="gdlr-core-head">
                                          <i className="icon-user"></i>
                                        </span>
                                        <a
                                          href="singleblog.html"
                                          title="Posts by John Smith"
                                          rel="author"
                                        >
                                          John Smith
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div className="gdlr-core-item-list gdlr-core-item-pdlr gdlr-core-item-mgb gdlr-core-column-30">
                            <div className="gdlr-core-blog-modern gdlr-core-with-image gdlr-core-hover-overlay-content gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover gdlr-core-style-1 gdlr-core-outer-frame-element">
                              <a href="/singleblog">
                                <div className="gdlr-core-blog-modern-inner">
                                  <div className="gdlr-core-blog-thumbnail gdlr-core-media-image">
                                    <img
                                      src="upload/shutterstock_231095752-600x600.jpg"
                                      alt=""
                                      width="600"
                                      height="600"
                                      title="shutterstock_231095752"
                                    />
                                  </div>
                                  <div className="gdlr-core-blog-modern-content gdlr-core-center-align">
                                    <h3
                                      className="gdlr-core-blog-title gdlr-core-skin-title"
                                      style={{
                                        fontSize: "26px",
                                        fontWeight: 700,
                                        letterSpacing: "0px",
                                      }}
                                    >
                                      <a href="singleblog.html">
                                        How To Improve Your Working From Home
                                      </a>
                                    </h3>
                                    <div className="gdlr-core-blog-info-wrapper gdlr-core-skin-divider">
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                                        <span className="gdlr-core-blog-info-sep">
                                          •
                                        </span>
                                        <span className="gdlr-core-head">
                                          <i className="icon-clock"></i>
                                        </span>
                                        <a href="singleblog.html">
                                          15 Oct 2020
                                        </a>
                                      </span>
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-author">
                                        <span className="gdlr-core-blog-info-sep">
                                          •
                                        </span>
                                        <span className="gdlr-core-head">
                                          <i className="icon-user"></i>
                                        </span>
                                        <a
                                          href="singleblog.html"
                                          title="Posts by John Smith"
                                          rel="author"
                                        >
                                          John Smith
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div className="gdlr-core-item-list gdlr-core-item-pdlr gdlr-core-item-mgb gdlr-core-column-30 gdlr-core-column-first">
                            <div className="gdlr-core-blog-modern gdlr-core-with-image gdlr-core-hover-overlay-content gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover gdlr-core-style-1 gdlr-core-outer-frame-element">
                              <a href="/singleblog">
                                <div className="gdlr-core-blog-modern-inner">
                                  <div className="gdlr-core-blog-thumbnail gdlr-core-media-image">
                                    <img
                                      src="upload/shutterstock_1281280267-600x600.jpg"
                                      alt=""
                                      width="600"
                                      height="600"
                                      title="shutterstock_1281280267"
                                    />
                                  </div>
                                  <div className="gdlr-core-blog-modern-content gdlr-core-center-align">
                                    <h3
                                      className="gdlr-core-blog-title gdlr-core-skin-title"
                                      style={{
                                        fontSize: "26px",
                                        fontWeight: 700,
                                        letterSpacing: "0px",
                                      }}
                                    >
                                      <a href="singleblog.html">
                                        Zoom launches Zapps into video calls
                                      </a>
                                    </h3>
                                    <div className="gdlr-core-blog-info-wrapper gdlr-core-skin-divider">
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                                        <span className="gdlr-core-blog-info-sep">
                                          •
                                        </span>
                                        <span className="gdlr-core-head">
                                          <i className="icon-clock"></i>
                                        </span>
                                        <a href="singleblog.html">
                                          15 Oct 2020
                                        </a>
                                      </span>
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-author">
                                        <span className="gdlr-core-blog-info-sep">
                                          •
                                        </span>
                                        <span className="gdlr-core-head">
                                          <i className="icon-user"></i>
                                        </span>
                                        <a
                                          href="singleblog.html"
                                          title="Posts by John Smith"
                                          rel="author"
                                        >
                                          John Smith
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                          <div className="gdlr-core-item-list gdlr-core-item-pdlr gdlr-core-item-mgb gdlr-core-column-30">
                            <div className="gdlr-core-blog-modern gdlr-core-with-image gdlr-core-hover-overlay-content gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover gdlr-core-style-1 gdlr-core-outer-frame-element">
                              <a href="/singleblog">
                                <div className="gdlr-core-blog-modern-inner">
                                  <div className="gdlr-core-blog-thumbnail gdlr-core-media-image">
                                    <img
                                      src="upload/shutterstock_1610966002-600x600.jpg"
                                      alt=""
                                      width="600"
                                      height="600"
                                      title="shutterstock_1610966002"
                                    />
                                  </div>
                                  <div className="gdlr-core-blog-modern-content gdlr-core-center-align">
                                    <h3
                                      className="gdlr-core-blog-title gdlr-core-skin-title"
                                      style={{
                                        fontSize: "26px",
                                        fontWeight: 700,
                                        letterSpacing: "0px",
                                      }}
                                    >
                                      <a href="singleblog.html">
                                        Google Cloud not to pry into customer
                                        data
                                      </a>
                                    </h3>
                                    <div className="gdlr-core-blog-info-wrapper gdlr-core-skin-divider">
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-date">
                                        <span className="gdlr-core-blog-info-sep">
                                          •
                                        </span>
                                        <span className="gdlr-core-head">
                                          <i className="icon-clock"></i>
                                        </span>
                                        <a href="singleblog.html">
                                          15 Oct 2020
                                        </a>
                                      </span>
                                      <span className="gdlr-core-blog-info gdlr-core-blog-info-font gdlr-core-skin-caption gdlr-core-blog-info-author">
                                        <span className="gdlr-core-blog-info-sep">
                                          •
                                        </span>
                                        <span className="gdlr-core-head">
                                          <i className="icon-user"></i>
                                        </span>
                                        <a
                                          href="singleblog.html"
                                          title="Posts by John Smith"
                                          rel="author"
                                        >
                                          John Smith
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div
                          className="gdlr-core-load-more-wrap gdlr-core-js gdlr-core-center-align gdlr-core-item-pdlr"
                          data-ajax="gdlr_core_post_ajax"
                        >
                          <a
                            href="#/"
                            className="gdlr-core-load-more gdlr-core-button-color"
                            data-ajax-name="paged"
                            data-ajax-value="2"
                          >
                            Load More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <commons.Footer />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Blog;
