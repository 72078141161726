import React, { Fragment } from "react";

const Footer = () => {
  return (
    <Fragment>
      <footer>
        <div className="arki-footer-wrapper">
          <div className="arki-footer-container arki-container clearfix">
            <div className="arki-footer-column arki-item-pdlr arki-column-20">
              <div id="text-1" className="widget widget_text arki-widget">
                <div className="textwidget">
                  <div>
                    <img
                      className="alignnone size-full wp-image-14474"
                      src="upload/flogo.png"
                      alt=""
                      width="82"
                    />
                  </div>
                  <div></div>
                  <div
                    style={{
                      maxWidth: "290px",
                      marginBottom: "30px",
                      marginTop: "30px",
                    }}
                  >
                    Thank you for visiting our website. If you would like to
                    learn more about our projects, then do check out our
                    Instagram page and hit the follow button for day to day
                    updates.
                  </div>
                  <p>
                    <span style={{ color: " #000" }}>
                      Copyright ©2022 Vevon.
                      <br />
                      All Rights Reserved.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="arki-footer-column arki-item-pdlr arki-column-20">
              <div id="text-6" className="widget widget_text arki-widget">
                <h1 className="arki-widget-title">Contact</h1>
                <span className="clear"></span>
                <div className="textwidget">
                  <a
                    href="https://www.google.com/maps/place/MODULO+CERAMICS/@16.4937818,80.6453092,15z/data=!4m5!3m4!1s0x0:0x9c56f2c9947b2e27!8m2!3d16.4937811!4d80.6453107"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h6 style={{ color: "#000" }}>Head Office Address</h6>
                    <p className="p1">
                      <span className="s1">
                        G4, AB Plaza,
                        <br /> Poornachandra Nagar Road,
                        <br />
                        Vijayawada, Andhra Pradesh, 520013
                        <br />
                      </span>
                    </p>
                  </a>
                  <h6 style={{ color: "#000" }}>Factory Address</h6>
                  <p className="p1">
                    <span className="s1">
                      352/1, Krishnaraopalem,
                      <br /> Chimalapadu Panchayathi
                      <br />
                      A. Konduru Mandal,
                      <br />
                      Krishna Dt. A.P - 521 227
                      <br />
                    </span>
                  </p>
                  <p className="p1">
                    <span className="s1">
                      <a href="tel:7780258182">+91 7780258182</a>
                      <br />
                    </span>
                  </p>
                  <p className="p1">
                    <span className="s1">
                      <a
                        href="mailto:mail@vevon.in"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="__cf_email__"
                        data-cfemail="d0b1a2bbb990b1a2bbb9b3bfa2a0feb3bf"
                      >
                        mail@vevon.in
                      </a>
                    </span>
                  </p>
                  <p className="p1"></p>
                </div>
              </div>
            </div>
            <div className="arki-footer-column arki-item-pdlr arki-column-20">
              <div id="text-2" className="widget widget_text arki-widget">
                <h1 className="arki-widget-title">Follow Us</h1>
                <span className="clear"></span>
                <div className="textwidget">
                  <p>
                    <a
                      href="https://www.facebook.com/Vevon-Ceramics-108284508429161/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className="fa fa-facebook-f"
                        style={{
                          color: "#000",
                          marginLeft: "3px",
                          marginRight: "17px",
                        }}
                      ></i>
                      Facebook
                    </a>
                    <br />
                    <span
                      className="gdlr-core-space-shortcode"
                      style={{ marginTop: "10px" }}
                    ></span>
                    <br />
                    <a
                      href="https://instagram.com/_.vevonceramics._?utm_medium=copy_link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className="fa fa-instagram"
                        style={{ color: "#000", marginRight: "13px" }}
                      ></i>
                      Instagram
                    </a>
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
