import Footer from "./Footer";
import Header from "./Header";
import MobileHeader from "./MobileHeader";
import PageHeader from "./PageHeader";
import { Link, useLocation } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";

const commons = {
  Header,
  MobileHeader,
  Footer,
  PageHeader,
  Link,
  useLocation,
  OwlCarousel,
};

export default commons;
