import React, { Fragment } from "react";
import commons from "./common/commons";

const Categories = () => {
  return (
    <Fragment>
      <div
        className="page-template-default page page-id-13775 theme-arki gdlr-core-body woocommerce-no-js arki-body arki-body-front arki-full arki-with-sticky-navigation arki-blockquote-style-3 gdlr-core-link-to-lightbox"
        data-home-url="/"
      >
        <commons.MobileHeader name="Categories" />
        <div className="arki-body-outer-wrapper mm-page mm-slideout">
          <div className="arki-body-wrapper clearfix arki-with-transparent-header arki-with-frame">
            <commons.Header name="categories" />
            <commons.PageHeader name="Categories" tag="Our Categories" />
            <div className="arki-page-wrapper" id="arki-page-wrapper">
              <div className="gdlr-core-page-builder-body">
                <div className="gdlr-core-pbf-section">
                  <div className="gdlr-core-pbf-section-container gdlr-core-container clearfix">
                    <div className="gdlr-core-pbf-element">
                      <div
                        className="gdlr-core-portfolio-item gdlr-core-item-pdb clearfix gdlr-core-portfolio-item-style-modern"
                        style={{ paddingBottom: "10px" }}
                      >
                        <div
                          className="gdlr-core-portfolio-item-holder gdlr-core-js-2 clearfix filter-container"
                          data-layout="masonry"
                        >
                          <div className="gdlr-core-item-list gdlr-core-item-pdlr gdlr-core-item-mgb gdlr-core-column-30 class1">
                            <commons.Link
                              to="/category"
                              state={{ category: "Glossy Tiles" }}
                            >
                              <div className="gdlr-core-portfolio-modern">
                                <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image gdlr-core-style-title-tag">
                                  <div className="gdlr-core-portfolio-thumbnail-image-wrap gdlr-core-zoom-on-hover">
                                    <img
                                      src="upload/categories/glossy-tiles-category.png"
                                      alt="imagee alt"
                                      width="700"
                                      height="450"
                                      title="glossy-tiles-category"
                                    />

                                    <span className="gdlr-core-image-overlay gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                      <span className="gdlr-core-image-overlay-content">
                                        <span
                                          className="gdlr-core-portfolio-title gdlr-core-title-font"
                                          style={{
                                            fontSize: "23px",
                                            fontWeight: 600,
                                            letterSpacing: "0px",
                                            textTransform: "none",
                                          }}
                                        >
                                          <commons.Link
                                            to="/category"
                                            state={{
                                              category: "Glossy Tiles",
                                            }}
                                          >
                                            Glossy Tiles
                                          </commons.Link>
                                        </span>
                                        <span
                                          className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font"
                                          style={{
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                          }}
                                        >
                                          {" "}
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </commons.Link>
                          </div>

                          <div className="gdlr-core-item-list gdlr-core-item-pdlr gdlr-core-item-mgb gdlr-core-column-30 class2">
                            <commons.Link
                              to="/category"
                              state={{ category: "Kitchen Series" }}
                            >
                              <div className="gdlr-core-portfolio-modern">
                                <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image gdlr-core-style-title-tag">
                                  <div className="gdlr-core-portfolio-thumbnail-image-wrap gdlr-core-zoom-on-hover">
                                    <img
                                      src="upload/categories/kitchen-series-category.png"
                                      alt=""
                                      width="700"
                                      height="450"
                                      title="kitchen-series-category"
                                    />
                                    <span className="gdlr-core-image-overlay gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                      <span className="gdlr-core-image-overlay-content">
                                        <span
                                          className="gdlr-core-portfolio-title gdlr-core-title-font"
                                          style={{
                                            fontSize: "23px",
                                            fontWeight: 600,
                                            letterSpacing: "0px",
                                            textTransform: "none",
                                          }}
                                        >
                                          <commons.Link
                                            to="/category"
                                            state={{
                                              category: "Kitchen Series",
                                            }}
                                          >
                                            Kitchen Series
                                          </commons.Link>
                                        </span>
                                        <span
                                          className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font"
                                          style={{
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                          }}
                                        >
                                          {" "}
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </commons.Link>
                          </div>

                          <div className="gdlr-core-item-list gdlr-core-item-pdlr gdlr-core-item-mgb gdlr-core-column-30 class3">
                            <commons.Link
                              to="/category"
                              state={{ category: "Pooja Room Series" }}
                            >
                              <div className="gdlr-core-portfolio-modern">
                                <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image gdlr-core-style-title-tag">
                                  <div className="gdlr-core-portfolio-thumbnail-image-wrap gdlr-core-zoom-on-hover">
                                    <img
                                      src="upload/categories/pooja-room-category.png"
                                      alt=""
                                      width="700"
                                      height="450"
                                      title="pooja-room-category"
                                    />
                                    <span className="gdlr-core-image-overlay gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                      <span className="gdlr-core-image-overlay-content">
                                        <span
                                          className="gdlr-core-portfolio-title gdlr-core-title-font"
                                          style={{
                                            fontSize: "23px",
                                            fontWeight: 600,
                                            letterSpacing: "0px",
                                            textTransform: "none",
                                          }}
                                        >
                                          <commons.Link
                                            to="/category"
                                            state={{
                                              category: "Pooja Room Series",
                                            }}
                                          >
                                            Pooja Room Series
                                          </commons.Link>
                                        </span>
                                        <span
                                          className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font"
                                          style={{
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                          }}
                                        >
                                          {" "}
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </commons.Link>
                          </div>

                          <div className="gdlr-core-item-list gdlr-core-item-pdlr gdlr-core-item-mgb gdlr-core-column-30 class1">
                            <commons.Link
                              to="/category"
                              state={{ category: "Elevation Series" }}
                            >
                              <div className="gdlr-core-portfolio-modern">
                                <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image gdlr-core-style-title-tag">
                                  <div className="gdlr-core-portfolio-thumbnail-image-wrap gdlr-core-zoom-on-hover">
                                    <img
                                      src="upload/categories/elevation-category.png"
                                      alt=""
                                      width="700"
                                      height="450"
                                      title="elevation-category"
                                    />
                                    <span className="gdlr-core-image-overlay gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                      <span className="gdlr-core-image-overlay-content">
                                        <span
                                          className="gdlr-core-portfolio-title gdlr-core-title-font"
                                          style={{
                                            fontSize: "23px",
                                            fontWeight: 600,
                                            letterSpacing: "0px",
                                            textTransform: "none",
                                          }}
                                        >
                                          <commons.Link
                                            to="/category"
                                            state={{
                                              category: "Elevation Series",
                                            }}
                                          >
                                            Elevation Series
                                          </commons.Link>
                                        </span>
                                        <span
                                          className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font"
                                          style={{
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                          }}
                                        >
                                          {" "}
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </commons.Link>
                          </div>

                          <div className="gdlr-core-item-list gdlr-core-item-pdlr gdlr-core-item-mgb gdlr-core-column-30 class1">
                            <commons.Link
                              to="/category"
                              state={{ category: "Corridor Sit Out Series" }}
                            >
                              <div className="gdlr-core-portfolio-modern">
                                <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image gdlr-core-style-title-tag">
                                  <div className="gdlr-core-portfolio-thumbnail-image-wrap gdlr-core-zoom-on-hover">
                                    <img
                                      src="upload/categories/corridor-sit-out-category.png"
                                      alt=""
                                      width="700"
                                      height="450"
                                      title="corridor-sit-out-category"
                                    />
                                    <span className="gdlr-core-image-overlay gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                      <span className="gdlr-core-image-overlay-content">
                                        <span
                                          className="gdlr-core-portfolio-title gdlr-core-title-font"
                                          style={{
                                            fontSize: "23px",
                                            fontWeight: 600,
                                            letterSpacing: "0px",
                                            textTransform: "none",
                                          }}
                                        >
                                          <commons.Link
                                            to="/category"
                                            state={{
                                              category:
                                                "Corridor Sit Out Series",
                                            }}
                                          >
                                            Corridor Sit Out Series
                                          </commons.Link>
                                        </span>
                                        <span
                                          className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font"
                                          style={{
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                          }}
                                        >
                                          {" "}
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </commons.Link>
                          </div>

                          <div className="gdlr-core-item-list gdlr-core-item-pdlr gdlr-core-item-mgb gdlr-core-column-30 class2">
                            <commons.Link
                              to="/category"
                              state={{ category: "Plain" }}
                            >
                              <div className="gdlr-core-portfolio-modern">
                                <div className="gdlr-core-portfolio-thumbnail gdlr-core-media-image gdlr-core-style-title-tag">
                                  <div className="gdlr-core-portfolio-thumbnail-image-wrap gdlr-core-zoom-on-hover">
                                    <img
                                      src="upload/categories/plain-category.png"
                                      alt="plain-category"
                                      width="700"
                                      height="450"
                                      title="plain-category"
                                    />
                                    <span className="gdlr-core-image-overlay gdlr-core-portfolio-overlay gdlr-core-image-overlay-center-icon gdlr-core-js">
                                      <span className="gdlr-core-image-overlay-content">
                                        <span
                                          className="gdlr-core-portfolio-title gdlr-core-title-font"
                                          style={{
                                            fontSize: "23px",
                                            fontWeight: 600,
                                            letterSpacing: "0px",
                                            textTransform: "none",
                                          }}
                                        >
                                          <commons.Link
                                            to="/category"
                                            state={{
                                              category: "Plain",
                                            }}
                                          >
                                            Plain
                                          </commons.Link>
                                        </span>
                                        <span
                                          className="gdlr-core-portfolio-info gdlr-core-portfolio-info-tag gdlr-core-info-font"
                                          style={{
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                          }}
                                        >
                                          {" "}
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </commons.Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <commons.Footer />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Categories;
