import React, { Fragment } from "react";
import commons from "./common/commons";

const About = () => {
  return (
    <Fragment>
      <div
        className="page-template-default page page-id-13775 theme-arki gdlr-core-body woocommerce-no-js arki-body arki-body-front arki-full arki-with-sticky-navigation arki-blockquote-style-3 gdlr-core-link-to-lightbox"
        data-home-url="index.html"
      >
        <commons.MobileHeader name="About" />
        <div className="arki-body-outer-wrapper mm-page mm-slideout">
          <div className="arki-body-wrapper clearfix arki-with-transparent-header arki-with-frame">
            <commons.Header name="about" />
            <commons.PageHeader name="About" tag="Our Strory" />
            <div className="arki-page-wrapper" id="arki-page-wrapper">
              <div className="gdlr-core-page-builder-body">
                <div className="gdlr-core-pbf-wrapper" id="gdlr-core-wrapper-1">
                  <div
                    className="gdlr-core-pbf-background-wrap"
                    id="div_e5bf_0"
                  >
                    <div
                      className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                      id="div_e5bf_1"
                      data-parallax-speed="0"
                    ></div>
                  </div>
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first"
                        id="gdlr-core-column-28245"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          id="div_e5bf_2"
                        >
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align">
                                <div
                                  className="gdlr-core-divider-container"
                                  id="div_e5bf_3"
                                >
                                  <div
                                    className="gdlr-core-divider-line gdlr-core-skin-divider"
                                    id="div_e5bf_4"
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                                <div className="gdlr-core-title-item-title-wrap">
                                  <h3
                                    className="gdlr-core-title-item-title gdlr-core-skin-title"
                                    id="h3_e5bf_0"
                                  >
                                    Vevon’s collection elevates every interior
                                    beyond perfection.
                                    <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align"
                                id="div_e5bf_5"
                              >
                                <div
                                  className="gdlr-core-divider-container"
                                  id="div_e5bf_6"
                                >
                                  <div
                                    className="gdlr-core-divider-line gdlr-core-skin-divider"
                                    id="div_e5bf_7"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-30"
                        id="gdlr-core-column-71803"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          id="div_e5bf_8"
                        >
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                id="div_e5bf_9"
                              >
                                <div
                                  className="gdlr-core-text-box-item-content"
                                  id="div_e5bf_10"
                                >
                                  <p>
                                    If you are looking for out of the box tile
                                    designs offering multiple variations in
                                    terms of color, hues, pattern and sizes, you
                                    are just at the right place. From simple,
                                    mild and minimalistic designs to gorgeous
                                    and aesthetically pleasing displays, we do
                                    it all.
                                  </p>
                                  <p>
                                    Handcrafted by our artisans at the factory,
                                    these potentially exquisite ceramic ranges
                                    could be tailored to any lifestyle. We are
                                    driven by versatility and passion for our
                                    art and so we cater to your housing
                                    requirements by prioritizing your vision.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gdlr-core-pbf-wrapper" id="gdlr-core-wrapper-2">
                  <div className="gdlr-core-pbf-background-wrap"></div>
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first"
                        id="gdlr-core-column-55792"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          id="div_e5bf_12"
                        >
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-image-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-item-pdlr"
                                id="div_e5bf_13"
                              >
                                <div
                                  className="gdlr-core-image-item-wrap gdlr-core-media-image gdlr-core-image-item-style-rectangle"
                                  id="div_e5bf_14"
                                >
                                  <img
                                    src="upload/shutterstock_492714445-1000x670.jpg"
                                    alt="imagee alt"
                                    width="1000"
                                    height="670"
                                    title="shutterstock_492714445"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-20"
                        id="gdlr-core-column-20310"
                      >
                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js">
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-image-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-item-pdlr"
                                id="div_e5bf_15"
                              >
                                <div
                                  className="gdlr-core-image-item-wrap gdlr-core-media-image gdlr-core-image-item-style-rectangle"
                                  id="div_e5bf_16"
                                >
                                  <img
                                    src="upload/inteco_architecture_2_about_page_header_bg-1000x670.jpg"
                                    alt=""
                                    width="1000"
                                    height="670"
                                    title="inteco_architecture_2_about_page_header_bg"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-20"
                        id="gdlr-core-column-86332"
                      >
                        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js">
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-image-item gdlr-core-item-pdb gdlr-core-center-align gdlr-core-item-pdlr"
                                id="div_e5bf_17"
                              >
                                <div
                                  className="gdlr-core-image-item-wrap gdlr-core-media-image gdlr-core-image-item-style-rectangle"
                                  id="div_e5bf_18"
                                >
                                  <img
                                    src="upload/about-image-3.png"
                                    alt=""
                                    width="1000"
                                    height="670"
                                    title="about-imagee-3"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gdlr-core-pbf-wrapper" id="gdlr-core-wrapper-3">
                  <div className="gdlr-core-pbf-background-wrap">
                    <div
                      className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                      id="div_e5bf_19"
                      data-parallax-speed="0"
                    ></div>
                  </div>
                  <div className="gdlr-core-pbf-wrapper-content gdlr-core-js">
                    <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
                        id="gdlr-core-column-47254"
                        style={{ marginBottom: 30 }}
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          id="div_e5bf_20"
                        >
                          <div className="gdlr-core-pbf-background-wrap"> </div>
                          {/* <iframe
                            width="700"
                            height="500"
                            src="https://www.youtube.com/embed/VuL2Ws0DI_Q"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe> */}
                          <iframe
                            width="700"
                            height="480"
                            src="https://www.youtube.com/embed/WfLhmPTM4HM"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      {/* <div
                        className="gdlr-core-pbf-column gdlr-core-column-30"
                        id="gdlr-core-column-95053"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js gdlr-core-column-extend-right"
                          id="div_e5bf_21"
                        >
                          <div className="gdlr-core-pbf-background-wrap"> </div>
                       
                          <iframe
                            width="700"
                            height="600"
                            src="https://www.youtube.com/embed/ORpOTPOO_NQ"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>

                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js"></div>
                        </div>
                      </div> */}
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first"
                        id="gdlr-core-column-97213"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          id="div_e5bf_23"
                        >
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-left-align gdlr-core-column-service-icon-top gdlr-core-no-caption gdlr-core-item-pdlr"
                                id="div_e5bf_24"
                              >
                                <div
                                  className="gdlr-core-column-service-media gdlr-core-media-image"
                                  id="div_e5bf_25"
                                >
                                  <img
                                    src="upload/icon1.png"
                                    alt=""
                                    width="160"
                                    height="160"
                                    title="about-icon01"
                                  />
                                </div>
                                <div className="gdlr-core-column-service-content-wrapper">
                                  <div className="gdlr-core-column-service-title-wrap">
                                    <h3
                                      className="gdlr-core-column-service-title gdlr-core-skin-title"
                                      id="h3_e5bf_1"
                                    >
                                      OFFERING ALL VARIETIES
                                    </h3>
                                  </div>
                                  <div
                                    className="gdlr-core-column-service-content"
                                    id="div_e5bf_26"
                                  >
                                    <p>
                                      From bathroom and kitchen to bedroom and
                                      living room, we deliver every possible set
                                      of digital wall tiles to give our
                                      customers an array of various experiences.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align">
                                <div
                                  className="gdlr-core-divider-container"
                                  id="div_e5bf_27"
                                >
                                  <div
                                    className="gdlr-core-divider-line gdlr-core-skin-divider"
                                    id="div_e5bf_28"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-20"
                        id="gdlr-core-column-14033"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          id="div_e5bf_29"
                        >
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-left-align gdlr-core-column-service-icon-top gdlr-core-no-caption gdlr-core-item-pdlr"
                                id="div_e5bf_30"
                              >
                                <div
                                  className="gdlr-core-column-service-media gdlr-core-media-image"
                                  id="div_e5bf_31"
                                >
                                  <img
                                    src="upload/icon2.png"
                                    alt=""
                                    width="160"
                                    height="160"
                                    title="about-icon-02"
                                  />
                                </div>
                                <div className="gdlr-core-column-service-content-wrapper">
                                  <div className="gdlr-core-column-service-title-wrap">
                                    <h3
                                      className="gdlr-core-column-service-title gdlr-core-skin-title"
                                      id="h3_e5bf_2"
                                    >
                                      AMPLIFYING INTERIOR DELIGHT
                                    </h3>
                                  </div>
                                  <div
                                    className="gdlr-core-column-service-content"
                                    id="div_e5bf_32"
                                  >
                                    <p>
                                      Our wall tiles are aesthetically pleasing
                                      to the eyes which is the primary aspect
                                      when it comes to home improvement and
                                      decoration.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align">
                                <div
                                  className="gdlr-core-divider-container"
                                  id="div_e5bf_33"
                                >
                                  <div
                                    className="gdlr-core-divider-line gdlr-core-skin-divider"
                                    id="div_e5bf_34"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="gdlr-core-pbf-column gdlr-core-column-20"
                        id="gdlr-core-column-63602"
                      >
                        <div
                          className="gdlr-core-pbf-column-content-margin gdlr-core-js"
                          id="div_e5bf_35"
                        >
                          <div className="gdlr-core-pbf-background-wrap"></div>
                          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js">
                            <div className="gdlr-core-pbf-element">
                              <div
                                className="gdlr-core-column-service-item gdlr-core-item-pdb gdlr-core-left-align gdlr-core-column-service-icon-top gdlr-core-no-caption gdlr-core-item-pdlr"
                                id="div_e5bf_36"
                              >
                                <div
                                  className="gdlr-core-column-service-media gdlr-core-media-image"
                                  id="div_e5bf_37"
                                >
                                  <img
                                    src="upload/icon3.png"
                                    alt=""
                                    width="160"
                                    height="160"
                                    title="about-icon01"
                                  />
                                </div>
                                <div className="gdlr-core-column-service-content-wrapper">
                                  <div className="gdlr-core-column-service-title-wrap">
                                    <h3
                                      className="gdlr-core-column-service-title gdlr-core-skin-title"
                                      id="h3_e5bf_3"
                                    >
                                      STRONG & DURABLE
                                    </h3>
                                  </div>
                                  <div
                                    className="gdlr-core-column-service-content"
                                    id="div_e5bf_38"
                                  >
                                    <p>
                                      To assure maximum customer satisfaction,
                                      we always pay attention to the strength
                                      and durability of raw materials that go
                                      into the manufacturing process.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-pbf-element">
                              <div className="gdlr-core-divider-item gdlr-core-divider-item-normal gdlr-core-item-pdlr gdlr-core-left-align">
                                <div
                                  className="gdlr-core-divider-container"
                                  id="div_e5bf_39"
                                >
                                  <div
                                    className="gdlr-core-divider-line gdlr-core-skin-divider"
                                    id="div_e5bf_40"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <commons.Footer />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default About;
