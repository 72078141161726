import React, { Fragment } from "react";

const PageHeader = ({ name, tag }) => {
  return (
    <Fragment>
      <div className="arki-page-title-wrap arki-style-custom arki-center-align">
        <div className="arki-header-transparent-substitute"></div>
        <div className="arki-page-title-overlay"></div>
        <div className="arki-page-title-container arki-container">
          <div className="arki-page-title-content arki-item-pdlr">
            <h1 className="arki-page-title">{name}</h1>
            <div className="arki-page-caption">{tag}</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PageHeader;
